import { Box, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, LinearProgress, Radio, RadioGroup, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import useSharedStyles from "components/useSharedStyles";
import { useAlert } from "context/AlertProvider";
import endpoints from "endpoints";
import useTeacherInit from "loaders/useTeacherInit";
import { justFetch } from "mutations/mutate";
import { IStudentRowData } from "pages/students/IStudentRowData";
import StudentActionConfirmationList from "pages/students/StudentActionConfirmationList";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { IClassDialogProps } from "../../../pages/classes/IClassDialogProps";
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { endOfDay, format } from "date-fns";

const ResetClassProgressDialog: React.VFC<IClassDialogProps> = ({ classId, open, onClose }) => {
  const { teacherData } = useTeacherInit();
  const klassStudents = useMemo(() => {
    const klass = teacherData?.klasses.find(klass => klass.id === classId);
    const teacher = teacherData?.teachers.find(teacher => klass?.teacher_id === teacher.id);
    if (klass && teacher) {
      return teacherData!.students.filter(student => student.klass_id === classId).map(student => ({ ...student, klass, teacher }));
    }

    return [];
  }, [teacherData, classId]);

  const [selectedStudents, setSelectedStudents] = useState<IStudentRowData[]>([]);

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | false>(false);
  const alert = useAlert();
  const sharedClasses = useSharedStyles();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (open) {
      setSelectedStudents([]);
      setResetMode(undefined);
      setResetLimitDate(null);
      setConfirmation(false);
    }
  }, [open]);

  const [resetMode, setResetMode] = useState<'date' | 'all' | undefined>();
  const [resetLimitDate, setResetLimitDate] = useState<Date | null>(null);
  const [confirmation, setConfirmation] = useState(false);

  const onSelect = (student: IStudentRowData, selected: boolean) => {
    if (selected) {
      setSelectedStudents(students => students.concat(student));
    } else {
      setSelectedStudents(students => students.filter(({ id }) => id !== student.id));
    }
  }

  const handleSubmit = () => {
    setSubmitting(true);
    justFetch(endpoints.resetStudentsProgress, 'POST', { student_ids: selectedStudents.map(({ id }) => id), limit_date: resetMode === 'date' ? endOfDay(resetLimitDate!).toISOString() : undefined })
      .then(res => {
        setSubmitting(false);
        if (!res.ok) {
          res.json().then(error => setError(error?.message || 'An unknown error occurred.'));
        }

        alert.success('Students\' progress reset successfully');
        history.push(location.pathname);
        onClose();
      })
      .catch(() => setError('An unknown error occurred.'))
  }


  return <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Dialog fullWidth open={open}>
      <LinearProgress style={{ visibility: submitting ? 'visible' : 'hidden' }} />
      <DialogTitle>Reset Students' Progress</DialogTitle>
      {!confirmation && <DialogContent>
        {klassStudents.length > 0 && <RadioGroup
          onChange={e =>
            setResetMode(e.target.value as any)
          }
          value={resetMode}
        >
          <FormControlLabel
            value="date"
            control={<Radio />}
            label={<Box>
              <Typography>Return progress to date</Typography>
              <Collapse in={resetMode === 'date'}>
                <Box mt={1}>
                  <DatePicker
                    onChange={setResetLimitDate}
                    value={resetLimitDate}
                    format="MM/dd/yyyy"
                    inputVariant="outlined"
                    InputProps={{
                      margin: 'dense'
                    }}
                    placeholder="Click to select a date"
                    maxDate={new Date()}
                  />
                </Box>
              </Collapse>
            </Box>}
          />
          <FormControlLabel
            value="all"
            control={<Radio />}
            label="Reset progress for all time"
          />
        </RadioGroup>}
        <Collapse in={resetMode === 'all' || (resetMode === 'date' && !!resetLimitDate)}>
          <Box mt={2} className={sharedClasses.vspacing2}>
            <Divider />
            <Typography>Select the students that you would like to reset progress for: </Typography>
            <Box display="flex" alignItems="end" className={sharedClasses.hspacing2}>
              <Button
                color="purple"
                variant="contained"
                onClick={() => {
                  if (selectedStudents.length < klassStudents.length) {
                    setSelectedStudents(klassStudents);
                  } else {
                    setSelectedStudents([]);
                  }
                }}
              >{selectedStudents.length < klassStudents.length ? 'Select' : 'Deselect'} All</Button>
              <Typography>{selectedStudents.length} / {klassStudents.length} selected</Typography>
            </Box>
            <StudentActionConfirmationList students={klassStudents} selectedStudents={selectedStudents} onSelectStudent={onSelect} />
          </Box>
        </Collapse>

        {!!error && <Alert severity="error" action={<Button size="small" color="inherit" onClick={handleSubmit}>Try Again</Button>}>{error}</Alert>}
      </DialogContent>}
      {confirmation && <DialogContent className={sharedClasses.vspacing2}>
        {resetMode === 'date' && <Typography>The following students' progress will be reset to what it was on {format(resetLimitDate!, 'PPP')}. All progress made after the selected date will be erased.</Typography>}
        {resetMode === 'all' && <Typography>The following students' progress will be reset to zero:</Typography>}
        <StudentActionConfirmationList students={selectedStudents} />
      </DialogContent>}
      <DialogActions>
        <Box width="100%">
          <Box mt={2} display="flex" flexDirection="row" justifyContent="space-between">
            <Button
              onClick={onClose}
              disabled={submitting}
              variant="outlined"
            >Cancel</Button>
            <Button
              variant="contained"
              color="red"
              disabled={submitting || selectedStudents.length === 0}
              onClick={confirmation ? handleSubmit : () => setConfirmation(true)}
            >
              Reset Students' Progress
            </Button>
          </Box>

        </Box>
      </DialogActions>
    </Dialog>
  </MuiPickersUtilsProvider>
}

export default ResetClassProgressDialog;