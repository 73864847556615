import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, MenuItem, Typography } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import Select from "components/ui/Select";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import { useTracking } from "context/TrackingProvider";
import endpoints from "endpoints";
import useCurrentUser from "loaders/useCurrentUser";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { routes } from "routes";
import useSWR from "swr";
import { gradesArray } from "types/Grades";
import { ITeacherData } from "types/ITeacherData";
import { teacherOnboardingContext } from "../../teacher-onboarding/TeacherOnboardingIndex";
import { Link as RouterLink } from 'react-router-dom';

const exampleNames = [
  "Brave Bears",
  "Amazing Rainbows",
  "Special Stars",
  "Happy Kangaroos",
  "Terrific Tigers",
  "Magical Lions",
  "Fantastic Frogs",
  "Gritty Lizards",
  "Inspiring Inchworms"
]

export const AddClassManualName: React.VFC = () => {
  const { data: teacherData} = useSWR<ITeacherData>(endpoints.teacherInit);
  const { track } = useTracking();
  const { currentUser } = useCurrentUser();
  const sharedClasses = useSharedStyles();
  const { addNewClassForm } = useContext(teacherOnboardingContext);
  const [canUseCleverImport, setCanUseCleverImport] = useState(false)

  useEffect(() => {
    if (!currentUser) {
      return
    }
    // All users with a School plan can use Clever import
    if (currentUser?.plan === 'School') {
      setCanUseCleverImport(true)
      return
    }
    // If they are a free user, they can still use Clever sync if their account was created before December 24, 2023, UTC
    const createdAt = new Date(currentUser?.created_at)
    const comparisonDate = new Date(Date.UTC(2023, 11, 24))
    if (createdAt < comparisonDate) {
      setCanUseCleverImport(true)
    }
  }, [currentUser])

  useEffect(() => {
    if (teacherData?.klasses.length === 0) {
      track('Viewed Onboarding Page', {
        'Page Name': 'Add Class Details'
      });
    }
  }, [teacherData]);

  const exampleName = useMemo(() => {
    return `${currentUser.name ? `${currentUser.name}'s ` : ''} ${exampleNames[Math.floor((exampleNames.length - 1) * Math.random())]}`
  }, [currentUser.name]);

  return <Box className={sharedClasses.vspacing2} display="flex" flexDirection="column" flexGrow={1}>
    <Box>
      {teacherData?.klasses.length === 0 && <Typography style={{ fontStyle: 'italic', marginBottom: 4 }}>Suggested: {exampleName}</Typography>}
      <TextField
        label={teacherData?.klasses.length !== 0 ? "Class Name" : undefined}
        placeholder="Type class name here"
        id="klass_name"
        value={addNewClassForm.values.klass_name}
        onChange={addNewClassForm.handleChange}
        helperText={addNewClassForm.errors.klass_name}
        error={!!addNewClassForm.errors.klass_name && addNewClassForm.submitCount > 0}
        onBlur={addNewClassForm.handleBlur}
        InputProps={{
          style: {
            background: 'white'
          }
        }}
      />
    </Box>
    <Select
      label="Class Grade"
      id="grade"
      onChange={e => addNewClassForm.setFieldValue('grade', e.target.value)}
      value={addNewClassForm.values.grade}
      fullWidth
      style={{
        background: 'white'
      }}
    >
      {gradesArray
        .map(grade => <MenuItem
          key={grade.key}
          value={grade.key}
        >
          {grade.name}
        </MenuItem>)
      }
    </Select>
    {((teacherData?.klasses.length || 0) > 0) && <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="flex-end">
      <Box display="flex" flexDirection="column" alignItems="center" className={sharedClasses.vspacing2} mt={2}>
        <Button
          color="purple"
          variant="outlined"
          {...{
            component: RouterLink,
            to: routes.classes.bulkAdd
          }}
        >Add&nbsp;Multiple&nbsp;Classes</Button>
        {(canUseCleverImport && teacherData?.has_clever_account) && <Button
          startIcon={<img style={{ height: '1.5rem' }} src="/images/CleverLogoInv.png" />}
          color="cleverBlue"
          variant="outlined"
          {...{
            component: RouterLink,
            to: routes.classes.import.clever
          }}
        >Add&nbsp;From&nbsp;Clever</Button>}
        <Button
          startIcon={<FontAwesomeIcon icon={faGoogle} />}
          color="red"
          variant="outlined"
          {...{
            component: RouterLink,
            to: routes.classes.import.google
          }}
        >Add&nbsp;From&nbsp;Google&nbsp;Classroom</Button>
      </Box>
    </Box>}
  </Box>;
}