import React, {useEffect, useState} from "react";
import useSharedStyles from "components/useSharedStyles";
import {useRouteMatch} from "react-router-dom";
import {IRouteParams} from "AppRoutes";
import {useOrganizationProfile} from "context/OrganizationProfileProvider";
import {ITeacher} from "types/ITeacher";
import {ISubscription} from "types/ISubscription";
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBriefcase, faEnvelope, faSchool} from "@fortawesome/free-solid-svg-icons";
import {accountTrackedDate} from "pages/godmode/utils/time";
import Button from "components/ui/buttons/Button";

export const OrganizationProfileDetailTeachersTab: React.FC = () => {
  const sharedClasses = useSharedStyles()
  const {params} = useRouteMatch<IRouteParams>();
  const {organization_profile} = useOrganizationProfile()
  const [teachers, setTeachers] = useState<ITeacher[]>()
  const [subscription, setSubscription] = useState<ISubscription>()

  useEffect(() => {
    if (!organization_profile?.teachers) {
      return
    }
    setTeachers(organization_profile?.teachers)
  }, [organization_profile])

  useEffect(() => {
    const sub = organization_profile?.subscriptions?.find((sub) => sub.id === Number(params?.subscriptionId))
    if (!sub) {
      return
    }
    setSubscription(sub)

    return () => {
      setSubscription(undefined)
    }

  }, [organization_profile, params])

  return (
    <Box className={sharedClasses?.vspacing4}>
      <Box className={sharedClasses?.vspacing2}>
        <Typography variant="h2">All
          - {subscription?.license_count} Site{subscription?.license_count !== 1 ? 's' : ''}</Typography>
        <Box display="flex" p={2} style={{border: '1px solid #eee', background: "white"}} width="65%">
          <Box className={sharedClasses?.vspacing2} display="flex" flexDirection="column" width="50%">
            <Typography variant="h1">Account Super Admin</Typography>
            <Typography>{organization_profile?.administrator?.name} | Admin
              Code: <strong>{organization_profile?.administrator?.administrator_code}</strong></Typography>
            <Typography><FontAwesomeIcon icon={faEnvelope}/> {organization_profile?.administrator?.username}
            </Typography>
            <Typography><FontAwesomeIcon icon={faBriefcase}/> {organization_profile?.administrator?.occupation}
            </Typography>
            <Typography><FontAwesomeIcon icon={faSchool}/> {organization_profile?.display_name}</Typography>
          </Box>
          <Box className={sharedClasses?.vspacing2} display="flex" flexDirection="column" width="50%"
               alignItems="flex-end">
            <Box textAlign="right">
              <Typography><strong>Signed Up</strong></Typography>
              <Typography>{organization_profile?.administrator?.created_at ? accountTrackedDate(organization_profile?.administrator?.created_at) : ''}</Typography>
            </Box>
            <Box textAlign="right">
              <Typography><strong>Last Active</strong></Typography>
              <Typography>{organization_profile?.administrator?.updated_at ? accountTrackedDate(organization_profile?.administrator?.updated_at) : ''}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box display="flex" alignItems="center" justifyContent="center" p={2}
             style={{background: '#5e2fac', color: 'white'}}>
          <Typography variant="h2">Linked Teachers {`(${teachers?.length})`}</Typography>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>School Name</TableCell>
                <TableCell>Admin</TableCell>
                <TableCell>Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teachers?.map((teacher, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{teacher?.name}</TableCell>
                    <TableCell>{teacher?.username}</TableCell>
                    <TableCell>{organization_profile?.display_name}</TableCell>
                    <TableCell>{organization_profile?.administrator?.name} {`(${organization_profile?.administrator?.username})`}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="red"
                        size="small"
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}