import Loading from "components/loading/Loading";
import { useRedirectToLogin } from "context/AuthProvider";
import React from "react";
import {Box, Typography} from "@material-ui/core";
import Button from "../components/ui/buttons/Button";

const LoadingPage: React.FC = () => {
  const { redirectToLogin } = useRedirectToLogin();

  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} height={'100%'} width={'100%'}>
      <img
        src="/images/KodableLogo.png"
        alt="Kodable"
        className="md:max-w-sm max-w-xs"
      />
      <Box height={250} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        <Loading />
      </Box>
      <Typography variant={'h5'} style={{fontWeight: 600, marginBottom: 20}} color={'textSecondary'}>Loading...</Typography>
      <Button
        onClick={() => redirectToLogin()}
        variant={'contained'}
        color={'kodableGreen'}
      >
        Not Loading?
      </Button>
    </Box>
  )
}

export default LoadingPage;