import { getOrderedCoursesBySection, orderedSections } from "pages/courses/courseSections";
import { IClassLessonUnassignment } from "components/dialogs/courses/EditLessonAssignmentsDialog";
import { ELessonType, generateOrderedLessons, ICourseData, IGalaxyCourse, ILevelCompletion, ILevelData } from "pages/courses/ICourseData";
import { IStudentSchoolProfile } from "../../types/IStudentSchoolProfile";
import { addMinutes } from 'date-fns';

export const levelCompletionMapping: { [key: number]: number } = {
  169: 617,
  171: 618,
  170: 620,
  501: 621,
  102: 622,
  103: 624,
  196: 627,
  189: 628,
  190: 629,
  191: 630
}

export interface IProgressReportRawData {
  level_completions: ILevelCompletion[];
  course_assignments: number[];
  lesson_omissions: IClassLessonUnassignment[];
  hoc_garbage_pickup_count: {
    student_id: number;
    hoc_garbage_pickup_count: number;
  }[];
}

export interface IProgressReportView {
  orderedCourses: {
    courseId: number;

    galaxyImage: string;
    dashboardTitle: string;
    emptyCourse: boolean;

    numLevels: number;

    orderedProgress: {
      completionPercentage: number;
      showAsPercent: boolean;
      completionDate?: Date;
      threeStarredEverything?: boolean;
      currentLocation?: boolean;
      hoc_garbage_pickup_count?: number;
    }[];

    orderedLessons?: {
      lessonId: string;
      type: ELessonType;
      dashboardTitle: string;

      orderedLevels: {
        levelId: number;

        dashboardTitle: string;
        orderedProgress: {
          stars: number;
        }[]
      }[]
    }[];
  }[];
}

const defaultIProgressReportView: IProgressReportView = {
  orderedCourses: []
}

export const generateClassProgressReportView = (courseData: ICourseData[], levelData: ILevelData[], orderedStudents: IStudentSchoolProfile[], reportData: { [key: string]: IProgressReportRawData }, paidUser: boolean, showAsPercent: boolean): IProgressReportView => {
  const course_assignments = Object.keys(reportData).reduce((memo: number[], klassId) => Array.from(new Set([...memo, ...reportData[klassId].course_assignments])), []);

  if (Object.keys(reportData).length === 0) {
    return defaultIProgressReportView;
  }

  return Object.keys(reportData)
    .map(klassId => {
      const filteredOrderedStudents = orderedStudents.filter(({ klass_id }) => klass_id?.toString() === klassId);
      return {
        ...generateStudentsProgressReportView(courseData, levelData, filteredOrderedStudents, { ...reportData[klassId], course_assignments }, paidUser, showAsPercent),
        klassId
      }
    })
    .sort((a, b) => parseInt(a.klassId) - parseInt(b.klassId))
    .reduce((_memo, klassReportView, _i, arr) => {
      const memo = _i === 1 ? { ..._memo, orderedCourses: _memo.orderedCourses.map((course, i) => ({ ...course, orderedProgress: [classProgressRollup(arr[0], i)] })) } : _memo;
      
      return {
        ...memo,
        orderedCourses: memo.orderedCourses.map((course, i) => {
          return {
            ...course,
            orderedProgress: course.orderedProgress.concat(classProgressRollup(klassReportView, i))
          }
        })
      }
    })
}

const classProgressRollup = (klassReportView: IProgressReportView, i: number) => {
  return klassReportView.orderedCourses[i].orderedProgress.reduce((memo, progress, _i) => {
    return {
      completionPercentage: (memo.completionPercentage * _i + progress.completionPercentage) / (_i + 1),
      hoc_garbage_pickup_count: klassReportView.orderedCourses[i].courseId === 7 ? (memo.hoc_garbage_pickup_count || 0) + (progress.hoc_garbage_pickup_count || 0) : undefined,
      showAsPercent: true
    }
  }, { completionPercentage: 0, hoc_garbage_pickup_count: 0, showAsPercent: true })
}

export const generateBulkStudentsProgressReportView = (courseData: ICourseData[], levelData: ILevelData[], orderedStudents: IStudentSchoolProfile[], reportData: { [key: string]: IProgressReportRawData }, paidUser: boolean, showAsPercent: boolean): IProgressReportView => {
  const course_assignments = Object.keys(reportData).reduce((memo: number[], klassId) => Array.from(new Set([...memo, ...reportData[klassId].course_assignments])), []);

  if (Object.keys(reportData).length === 0) {
    return defaultIProgressReportView;
  }

  return Object.keys(reportData)
    .map(klassId => {
      const filteredOrderedStudents = orderedStudents.filter(({ klass_id }) => klass_id?.toString() === klassId);
      return {
        ...generateStudentsProgressReportView(courseData, levelData, filteredOrderedStudents, { ...reportData[klassId], course_assignments }, paidUser, showAsPercent),
        klassId
      }
    })
    .sort((a, b) => parseInt(a.klassId) - parseInt(b.klassId))
    .reduce((memo, reportView) => {
      return {
        ...memo,
        orderedCourses: memo.orderedCourses.map((course, i) => {
          return {
            ...course,
            orderedProgress: course.orderedProgress.concat(reportView.orderedCourses[i].orderedProgress)
          }
        })
      }
    })
}

export const generateStudentsProgressReportView = (courseData: ICourseData[], levelData: ILevelData[], orderedStudents: IStudentSchoolProfile[], reportData: IProgressReportRawData, paidUser: boolean, showAsPercent: boolean): IProgressReportView => {
  const { level_completions, course_assignments, lesson_omissions, hoc_garbage_pickup_count } = reportData;
    
  level_completions.forEach(levelCompletion => {
    const mapping = levelCompletionMapping[levelCompletion.level_id];

    if (mapping) {
      level_completions.push({
        ...levelCompletion,
        level_id: mapping
      })
    }
  });

  const galaxyCourse = courseData?.find(({ id }) => id === 1) as IGalaxyCourse;
  const orderedCoursesBySection = getOrderedCoursesBySection(courseData);

  // student id => level id
  const processedLevelCompletions: { [key: number]: { latestUpdate?: { levelId: number, created_at: Date }, completions: { [key: number]: ILevelCompletion[] } } } = level_completions.reduce((processedLevelCompletions: { [key: number]: { latestUpdate?: { levelId: number, created_at: Date }, completions: { [key: number]: ILevelCompletion[] } } }, levelCompletion) => {
    const student = processedLevelCompletions[levelCompletion.student_id] || {
      completions: []
    };

    const currentUpdate = {
      levelId: levelCompletion.level_id,
      created_at: new Date(levelCompletion.created_at)
    };

    let latestUpdate = student.latestUpdate || currentUpdate;

    if (currentUpdate.created_at.getTime() - latestUpdate.created_at.getTime() > 0) {
      latestUpdate = currentUpdate;
    }

    const completions = (student.completions[levelCompletion.level_id] || []);

    completions.push(levelCompletion);

    return {
      ...processedLevelCompletions,
      [levelCompletion.student_id]: {
        latestUpdate,
        completions: {
          ...student.completions,
          [levelCompletion.level_id]: completions
        }
      }
    }
  }, {});

  const orderedCourses = orderedSections
    .reduce((acc: ICourseData[], section) => {
      return acc.concat(orderedCoursesBySection[section] || []);
    }, [])
    .filter(({ id }) => course_assignments.includes(id))
    .map(course => {
      const isBeachCleanup = course.id === 7;

      const orderedLessons = course.meta.emptyCourse ? [] : generateOrderedLessons(course, paidUser)
        .filter(({ nodeID }) => !lesson_omissions.find(({ course_id, lesson_node_id }) => course_id === course.id && lesson_node_id === nodeID));

      const orderedLevels = orderedLessons
        .reduce((orderedLevels: number[], lesson) => {
          return orderedLevels.concat(lesson.levels || []);
        }, []);

      return {
        courseId: course.id,

        galaxyImage: galaxyCourse.configuration.planetNodes.find(planetNode => planetNode.courseID === course.id)!.image,
        dashboardTitle: course?.meta?.dashboardTitle!,
        emptyCourse: course.meta.emptyCourse || false,

        numLevels: orderedLevels.length,

        orderedProgress: orderedStudents.map(student => {
          let completedLevels = 0;
          let completedThreeStarLevels = 0;
          let courseCompletionTime = 0;

          const studentLevelStats = orderedLevels.map(levelId => {
            const completions = processedLevelCompletions[student.id]?.completions[levelId] || [];
            const maxStars = completions.reduce((acc, cur) => Math.max(acc, cur.score), 0);
            const levelCompletionTime = completions.reduce((acc, cur) => Math.max(acc, new Date(cur.created_at).getTime()), 0);

            if (maxStars > 0) {
              completedLevels++;
            }

            if (maxStars === 3) {
              completedThreeStarLevels++;
            }

            if (levelCompletionTime > courseCompletionTime) {
              courseCompletionTime = levelCompletionTime;
            }


            return {
              levelId,
              maxStars
            }
          });

          return {
            studentId: student.id,
            name: student.name,
            completionPercentage: completedLevels / orderedLevels.length,
            completionDate: new Date(courseCompletionTime),
            showAsPercent,
            threeStarredEverything: completedThreeStarLevels === orderedLevels.length,
            currentLocation: studentLevelStats.some(({ levelId }) => levelId === processedLevelCompletions[student.id]?.latestUpdate?.levelId),
            hoc_garbage_pickup_count: isBeachCleanup ? hoc_garbage_pickup_count.find(({ student_id }) => student_id === student.id)?.hoc_garbage_pickup_count : undefined
          }
        }),

        orderedLessons: orderedLessons.map(lesson => {
          return {
            lessonId: lesson.nodeID,
            type: lesson.lessonType,
            dashboardTitle: lesson.meta?.dashboardTitle!,
            orderedLevels: (lesson.levels || [lesson.mediaLevelID!]).map(levelId => {
              const level = levelData.find(({ id }) => id === levelId);

              return {
                levelId,
                dashboardTitle: level?.meta?.dashboardTitle || level?.name!,
                orderedProgress: orderedStudents.map(student => {
                  const completions = processedLevelCompletions[student.id]?.completions[levelId] || [];
                  let stars = completions.reduce((acc, cur) => Math.max(acc, cur.score), 0);

                  if (lesson.lessonType === ELessonType.mediaContent && completions.length > 0) {
                    stars = 3;
                  }

                  return { stars }
                })
              }
            }) || []
          }
        })
      }
    });

  return {
    orderedCourses
  };
}

export const transformReportToCsv = (rowDescriptor: 'student' | 'class', rowTitles: string[], reportView: IProgressReportView) => {
  const columns: string[][] = [
    // header
    [
      rowDescriptor,
      ...rowTitles
    ],
    // each course
    ...reportView.orderedCourses.map(({ dashboardTitle, orderedProgress, courseId }) => {
      return [
        dashboardTitle,
        ...orderedProgress.map(({ completionPercentage, hoc_garbage_pickup_count }) => {
          if (courseId === 7) {
            return `${hoc_garbage_pickup_count || 0} items`
          }

          return `${completionPercentage * 100}%`;
        })
      ];
    })
  ];

  const rows: string[][] = [
    ...columns[0].map((_, rowIdx) => {
      return columns.map((colData) => {
        return colData[rowIdx] || '';
      })
    })
  ];

  const contents = [
    rows
      .map(rowData => 
        rowData.join(',')
      )
      .join('\n')
  ];

  return new Blob(contents, { type: 'text/csv;charset=utf-8' });
}

export const dateToUTC = (date: Date): Date => {
  return addMinutes(date, date.getTimezoneOffset())
}