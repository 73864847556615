import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import { useAlert } from "context/AlertProvider";
import endpoints from "endpoints";
import { useFormik } from "formik";
import { DialogStateProps } from "hooks/useDialogState";
import useCurrentUser from "loaders/useCurrentUser";
import { justFetch } from "mutations/mutate";
import React, { useEffect, useState } from "react";
import { mutate } from "swr";
import * as Yup from 'yup';

const LinkAccountDialog: React.VFC<DialogStateProps> = ({ open = false, onClose = () => { } }) => {
  const sharedClasses = useSharedStyles();
  const { currentUser } = useCurrentUser();
  const alert = useAlert();
  const form = useFormik({
    initialValues: {
      admin_code: ''
    },
    validationSchema: Yup.object({
      admin_code: Yup.string().required('Enter the code you received from your Kodable admin')
    }),
    onSubmit: values => {
      return justFetch(endpoints.linkAccount(currentUser.id), 'POST', values)
        .then(res => {
          if (res.status === 404 || res.status === 422) {
            form.setFieldError('admin_code', "We couldn't find that code. Double check that it's correct or talk to your Kodable admin.")
          } else if (!res.ok) {
            throw new Error();
          } else {
            mutate(endpoints.teacherInit);
            mutate(endpoints.session);
            onClose();
            alert.success('Admin Account Linked');
          }
        })
        .catch(() => setSubmitError(true))
    }
  });
  const [submitError, setSubmitError] = useState(false);

  useEffect(() => {
    if (open) {
      form.resetForm();
      setSubmitError(false);
    }
  }, [open]);

  return <Dialog open={open} fullWidth>
    <LinearProgress style={{ visibility: form.isSubmitting ? 'visible' : 'hidden' }} />
    <form onSubmit={form.handleSubmit}>
      <DialogTitle>
        Join My School's License
      </DialogTitle>
      <DialogContent className={sharedClasses.vspacing2}>
        <Typography>
        If your school or district has a paid license, you can link to the admin on the account and share student data with them.
        </Typography>
        <TextField
          id="admin_code"
          name="admin_code"
          label="Kodable Admin Code"
          placeholder="Enter the code you received from your Kodable admin via email"
          value={form.values.admin_code}
          onChange={form.handleChange}
          error={form.submitCount > 0 && !!form.errors.admin_code}
          helperText={form.submitCount > 0 ? form.errors.admin_code : undefined}
          disabled={form.isSubmitting}
        />
        {form.submitCount > 0 && !form.isValid && <Alert severity="error">Please correct the errors above to continue.</Alert>}
        {submitError && <Alert severity="error" action={<Button size="small" color="inherit" onClick={form.submitForm}>Try again</Button>}>There was an error submitting this form..</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          type="reset"
          onClick={onClose}
          disabled={form.isSubmitting}
        >Cancel</Button>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          startIcon={<FontAwesomeIcon icon={faLink} />}
          disabled={form.isSubmitting}
        >Link Account</Button>
      </DialogActions>
    </form>
  </Dialog>
}

export default LinkAccountDialog;