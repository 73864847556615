import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import React, { useMemo, useState } from "react";
import { IGodmodeUserProfile } from "../IGodmodeUserProfile";
import { UICollapsingBlock } from "../UICollapsingBlock";

export const ClassesBlock: React.FC<{ teacher: IGodmodeUserProfile }> = ({ teacher }) => {
  const [filterString, setFilterString] = useState('');
  const [numToShow, setNumToShow] = useState(5);
  const sharedClasses = useSharedStyles();
  const klasses = useMemo(() => {
    return teacher.klasses.slice().sort((a, b) => a.klass_name.localeCompare(b.klass_name)).filter((klass, index) => {
      return (klass.klass_name.includes(filterString) || klass.klass_code.includes(filterString)) && (index < numToShow || filterString.length > 0);
    })
  }, [filterString, numToShow, teacher.klasses])

  return <UICollapsingBlock color="rgb(250, 160, 16)" title={`Classes (${teacher.klasses.length})`}>
    <Box className={sharedClasses.vspacing2} p={2}>
      <Box>
        <TextField
          placeholder="Search"
          value={filterString}
          onChange={e => setFilterString(e.target.value)}
        />
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: "rgb(250, 160, 16)" }}>Class Name</TableCell>
              <TableCell style={{ color: "rgb(250, 160, 16)" }}>Class Code</TableCell>
              <TableCell style={{ color: "rgb(250, 160, 16)" }}>Grade</TableCell>
              <TableCell style={{ color: "rgb(250, 160, 16)" }}>Clever</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {klasses.map((klass, index) => {
              return <TableRow key={index}>
                <TableCell>{klass.klass_name}</TableCell>
                <TableCell>{klass.klass_code}</TableCell>
                <TableCell>{klass.grade === 'all' ? '6+' : (klass.grade?.toLocaleUpperCase() || 'None')}</TableCell>
                <TableCell>{!!klass.clever_id ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}</TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {klasses.length === 0 && <Alert severity="info">No classes to show.</Alert>}
      {teacher.klasses.length > klasses.length &&
          <Box display="flex" justifyContent="center">
            <Button
              onClick={() => setNumToShow(numToShow + 10)}
              variant="outlined"
            >
              Show More
            </Button>
          </Box>
      }
    </Box>
  </UICollapsingBlock>;
}