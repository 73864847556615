import { Box, Typography } from "@material-ui/core"
import { IRouteParams } from "AppRoutes"
import useTeacherInit from "loaders/useTeacherInit";
import { useParams } from "react-router-dom"
import { IStudentSchoolProfile } from "types/IStudentSchoolProfile";
import { IKlass } from 'types/IKlass'
import React from "react";

/**
 * 
 * @param studentCode student code of the student in the klass
 * @returns the name of a login icon / password, which is chosen using their student code
 */
const getStudentPassword = (studentCode: string) : string => {
    const LOGIN_ICONS : string[] = [
        'add', 'balloons', 'battery', 'bee', 'bell', 'bike', 'block',
        'broccoli', 'brush', 'bug', 'bunny', 'bus', 'cactus', 'car',
        'carrot', 'chair', 'coffee', 'diamond', 'duck', 'eye', 'fire',
        'firetruck', 'flag', 'flower', 'football', 'frenchfries', 'frog',
        'globe', 'graduationcap', 'guitar', 'hamburger', 'hammer', 'hat',
        'headphones', 'helmet', 'hoodie', 'hotdog', 'house', 'key',
        'lightbulb', 'megaphone', 'mitten', 'money', 'owl', 'pig', 'plane',
        'planet', 'pointer', 'reindeer', 'rocket', 'snowman', 'soccer-ball',
        'stop-light', 'sunglasses', 'taco', 'trophy', 'umbrella'
    ];
    const iconIndex : number = (
        studentCode.charCodeAt(0) + 
        studentCode.charCodeAt(1) + 
        studentCode.charCodeAt(2)
    ) % 57;
    return LOGIN_ICONS[iconIndex];
}

export const StudentHandoutsPrintout: React.VFC = () => {
    const { classId } = useParams<IRouteParams>();
    const { teacherData } = useTeacherInit();
    const students : IStudentSchoolProfile[] | undefined = teacherData?.students.filter(
        ({ klass_id }) => klass_id?.toString() === classId
    )
    const classData : IKlass | undefined = teacherData?.klasses?.filter(
        ({ id }) => id?.toString() === classId
    )[0]

    return <>
    {students?.map((student : IStudentSchoolProfile, index) => {
        const shouldPageBreakAfter : boolean = (students.indexOf(student) + 1) % 2 === 0 && (students.indexOf(student) + 1) !== students.length;
        
        return (
            <Box
                key={index}
                style={{
                    pageBreakAfter: (shouldPageBreakAfter ? 'always' : 'initial'),
                    background: "url('/images/printouts/student_code_card.jpg') no-repeat",
                    backgroundSize: 'cover'
                }}
                width="1275px"
                position="relative"
                height="825px"
            >
                <style>
                {`
                    @page {
                        size: 1275px 1650px;
                    }
                `}
                </style>
                
                <Typography
                    style={{
                        position: "absolute",
                        top: "253px",
                        left: "336px",
                        fontSize: "35px",
                        width: "776px",
                        height: "107px",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "39px",
                        boxSizing: "border-box"
                      }}
                >
                    {student?.name}
                </Typography>
                
                {(!classData?.password_enabled && !classData?.code_password_enabled) &&
                    <div
                        style={{
                            position: "absolute",
                            top: "636px",
                            right: "180px",
                            fontSize: "36px",
                            width: "300px",
                            height: "157px",
                            background: "white"
                        }}
                    ></div>
                }

                {classData?.code_password_enabled &&
                    <Typography
                    style={{
                        position: "absolute",
                        top: "648px",
                        right: "209px",
                        fontSize: "36px",
                        fontFamily: "monospace",
                        width: "258px",
                        height: "107px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                    >{student?.student_code}</Typography>
                }

                {classData?.password_enabled &&
                <div
                    style={{
                        position: "absolute",
                        top: "648px",
                        right: "209px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "258px",
                        height: "107px"
                    }}
                >
                    <img
                        style={{
                            display: "block"
                        }}
                        alt=""
                        src={
                            `/images/picture-passwords/${getStudentPassword(student?.student_code)}.png`
                        }
                    />
                </div>
                }

                <Typography
                    style={{
                        position: "absolute",
                        top: "647px",
                        left: "136px",
                        fontSize: "36px",
                        fontFamily: "monospace",
                        width: "533px",
                        textAlign: "center",
                        height: "103px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    {classData?.klass_code}
                </Typography>
                {(shouldPageBreakAfter || (students.indexOf(student) + 1) === students.length) &&
                    <div style={{
                        width: "100%",
                        height: "20px",
                        backgroundColor: "white",
                        position: "absolute",
                        bottom: "1px"
                    }}></div>
                }
            </Box>
        )
    })}
    </>
}