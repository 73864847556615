import {
  Box,
  Link,
  Paper,
  Typography
} from "@material-ui/core";
import { CalendarToday, CreditCard } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import useSharedStyles from "components/useSharedStyles";
import { format } from "date-fns";
import endpoints from "endpoints";
import useDialogState from "hooks/useDialogState";
import useSWR, { mutate } from "swr";
import { IGodmodeUserProfile } from "../IGodmodeUserProfile";
import PaymentsDialog from "./dialogs/PaymentsDialog";
import CreateTrialDialog from "./dialogs/CreateTrialDialog";
import UpgradeLifetimeDialog from "./dialogs/UpgradeLifetimeDialog";
import CancelStripeSubscriptionDialog from "./dialogs/CancelStripeSubscriptionDialog";
import RefundDowngradeDialog from "./dialogs/RefundDowngradeDialog";
import React from "react";


const weekAgo = new Date();
weekAgo.setDate(new Date().getDate() - 7);

export const SubscriptionDetailsBlock: React.FC<{ teacher: IGodmodeUserProfile }> = ({ teacher }) => {
  const subscription = teacher.parent_subscription || teacher.parent_old_subscription;
  const sharedClasses = useSharedStyles();
  const cancelStripeSubscriptionDialogState = useDialogState();
  const refundAndDowngradeStripeSubscriptionDialogState = useDialogState();
  const createTrialDialogState = useDialogState();
  const upgradeLifetimeDialogState = useDialogState();
  const paymentsDialogState = useDialogState();

  const { data: lastChargeData } = useSWR<undefined | { amount_refunded: number, amount: number, id: number }>(endpoints.stripeLastCharge(teacher.id));

  const revalidate = async () => {
    await mutate(endpoints.godmode.teacher(teacher.id), undefined, true);
  }

  const revalidateStripe = async () => {
    await mutate(endpoints.godmode.teacher(teacher.id), undefined, true);
    await mutate(endpoints.stripeLastCharge(teacher.id), undefined, true);
  }


  return <Box component={Paper} {...{ variant: 'outlined' }} p={2} height="100%" display="flex" flexDirection="column">
    <PaymentsDialog open={paymentsDialogState.open} onClose={paymentsDialogState.onClose} payments={teacher?.teacher_payments || []} />
    <CreateTrialDialog
      teacherId={teacher.id}
      open={createTrialDialogState.open}
      onClose={createTrialDialogState.onClose}
      onSuccess={() => {
        revalidate();
        createTrialDialogState.onClose();
      }}
    />
    <UpgradeLifetimeDialog
      teacherId={teacher.id}
      open={upgradeLifetimeDialogState.open}
      onClose={upgradeLifetimeDialogState.onClose}
      onSuccess={() => {
        revalidate();
        upgradeLifetimeDialogState.onClose();
      }}
    />
    <CancelStripeSubscriptionDialog
      teacherId={teacher.id}
      subscription={teacher.parent_subscription}
      open={cancelStripeSubscriptionDialogState.open}
      onClose={cancelStripeSubscriptionDialogState.onClose}
      onSuccess={() => {
        revalidate();
        cancelStripeSubscriptionDialogState.onClose();
      }}
    />
    <RefundDowngradeDialog
      teacherId={teacher.id}
      lastChargeId={lastChargeData?.id}
      open={refundAndDowngradeStripeSubscriptionDialogState.open}
      onClose={refundAndDowngradeStripeSubscriptionDialogState.onClose}
      onSuccess={() => {
        revalidateStripe();
        refundAndDowngradeStripeSubscriptionDialogState.onClose();
      }}
    />
    <Typography variant="h5">Parent Subscription</Typography>
    <Typography variant="body1">{teacher.plan} Plan</Typography>
    {subscription && <Box className={sharedClasses.vspacing1} mt={1} flexGrow={1}>
      <Box display="flex" alignItems="center" className={sharedClasses.hspacing1}>
        <CalendarToday />
        <Box>
          <Box component="span" style={{ textTransform: 'capitalize' }}>{subscription.term}</Box>
          {new Date(subscription.start_date) > weekAgo && ['monthly', 'yearly'].includes(subscription.term) ? ' Trial ' : ' '}({format(new Date(subscription.start_date), 'PP')} - {format(new Date(subscription.end_date), 'PP')})
        </Box>
      </Box>
      <Box>
        <Box display="flex" alignItems="center" className={sharedClasses.hspacing1}>
          <CreditCard />
          <Box style={{ textTransform: 'capitalize' }}>
            {subscription.source === 'STRIPE' ? <Link target="_blank" href={`https://dashboard.stripe.com/subscriptions/${subscription.stripe_subscription_id}`}>
              Stripe
            </Link> : subscription.source}
          </Box>
        </Box>
      </Box>
      {subscription.is_canceled && <Alert severity="warning">Subscription is set to expire at the end of the term.</Alert>}
    </Box>}
    <Box borderTop="1px solid rgba(0, 0, 0, 0.12)" mt={2} pt={2} display="flex" justifyContent="space-around" className={sharedClasses.hspacing2}>
      {(teacher.is_parent && teacher.parent_subscription?.is_canceled) && <Link onClick={createTrialDialogState.handleOpen} className={sharedClasses.hoverCursorPointer}>create trial</Link>}
      {(teacher.is_parent) && <Link onClick={upgradeLifetimeDialogState.handleOpen} className={sharedClasses.hoverCursorPointer}>upgrade to lifetime</Link>}
      {(subscription?.source === 'STRIPE' && !!teacher.teacher_payments) && <Link onClick={paymentsDialogState.handleOpen} className={sharedClasses.hoverCursorPointer}>show payments</Link>}
      {(teacher.is_parent && subscription?.source === 'STRIPE' && !!lastChargeData && !(lastChargeData.amount_refunded > 0)) && <Link onClick={refundAndDowngradeStripeSubscriptionDialogState.handleOpen} className={sharedClasses.hoverCursorPointer}>refund ${lastChargeData.amount / 100} and downgrade</Link>}
      {(teacher.is_parent && teacher.parent_subscription && !teacher.parent_subscription?.is_canceled) && <Link onClick={cancelStripeSubscriptionDialogState.handleOpen} className={sharedClasses.hoverCursorPointer}>cancel subscription</Link>}
    </Box>
  </Box>;
}