import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import useSharedStyles from "components/useSharedStyles";
import { useAlert } from "context/AlertProvider";
import endpoints from "endpoints";
import { justFetch } from "mutations/mutate";
import React, { useEffect } from "react";
import { useState } from "react";
import { mutate } from "swr";
import { IStudentsDialogProps } from "../../../pages/students/IStudentsDialogProps";
import StudentActionConfirmationList from "../../../pages/students/StudentActionConfirmationList";

const DeleteStudentsDialog: React.VFC<IStudentsDialogProps> = ({ open, onClose = () => { }, students }) => {
  const sharedClasses = useSharedStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const alert = useAlert();

  const handleDelete = () => {
    setIsSubmitting(true);
    justFetch(endpoints.deleteStudents, 'POST', { student_ids: students.map(({ id }) => id) })
      .then(res => {
        if (!res.ok) {
          throw new Error();
        }

        mutate(endpoints.teacherInit);
        setIsSubmitting(false);
        alert.success('Student Deleted');
        onClose();
      })
      .catch(() => {
        setIsSubmitting(false);
        setSubmitError(true);
      });
  }

  useEffect(() => {
    if (open) {
      setIsSubmitting(false);
      setSubmitError(false);
    }
  }, [open])

  return <Dialog fullWidth open={open}>
    <LinearProgress style={{ visibility: isSubmitting ? 'visible' : 'hidden' }} />
    <DialogTitle>Delete students?</DialogTitle>
    <DialogContent className={sharedClasses.vspacing2}>
      <Typography>
        This will delete {students.length > 1 ? `these ${students.length} students:` : `this student:`}
      </Typography>

      <StudentActionConfirmationList students={students} />

      {submitError && <Alert severity="error" action={
        <Button
          color="inherit"
          size="small"
          onClick={handleDelete}
        >Try again</Button>
      } >There was an error trying to submit this form.</Alert>}
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onClose}
        disabled={isSubmitting}
        variant="outlined"
      >
        Cancel
      </Button>
      <Button
        onClick={handleDelete}
        disabled={isSubmitting}
        variant="contained"
        color="red"
      >
        Delete
      </Button>
    </DialogActions>
  </Dialog>
}

export default DeleteStudentsDialog;