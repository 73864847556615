import endpoints from "endpoints";
import { justFetch } from "mutations/mutate";
import { useEffect, useState } from "react";

const possibleCodeCharacters = 'abcdefghijkmnpqrstuvwxyz23456789';
const codeLength = 7;

const useGenerateClassCode = () => {
  const [naughtyWords, setNaughtyWords] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const [classCode, setClassCode] = useState<string>('');
  const [classCodeError, setClassCodeError] = useState(false);

  useEffect(() => {
    fetch('naughtyWords.json')
      .then(res => {
        setLoading(false);

        if (res.ok) {
          return res.json();
        }
      })
      .then((naughtyWords: undefined | { naughtyWords: string[] }) => {
        if (naughtyWords) {
          setNaughtyWords(naughtyWords.naughtyWords);
        }

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (classCode === '' && !loading) {
      setLoading(true);

      tryGenerateClassCode(naughtyWords)
        .then(classCode => { setClassCode(classCode); setLoading(false); })
        .catch(() => setClassCodeError(true));
    }
  // eslint-disable-next-line
  }, []);

  const checkIfClassCodeUnique: (code: string) => Promise<boolean> = code => {
    return justFetch(endpoints.checkClassCode, 'POST', { klass_code: code })
      .then(res => {
        if (!res.ok) {
          throw new Error();
        }

        return res.json();
      })
  }

  return {
    classCode,
    loading: loading,
    error: classCodeError,
    checkIfClassCodeUnique
  }
}

const tryGenerateClassCode = (naughtyWords: string[]): Promise<string> => {
  const classCode = generateClassCode(naughtyWords);
  return justFetch(endpoints.checkClassCode, 'POST', { klass_code: classCode })
    .then(res => {
      if (!res.ok) {
        throw new Error();
      }

      return res.json();
    })
    .then((isUnique: boolean) => {
      if (!isUnique) {
        return tryGenerateClassCode(naughtyWords);
      } else {
        return classCode; 
      }
    });

}

const generateClassCode = (naughtyWords: string[]) => {
  while (true) {
    let code = '';
    for (let i = 0; i < codeLength; i++) {
      code += possibleCodeCharacters[Math.floor(Math.random() * possibleCodeCharacters.length)];
    }

    if (naughtyWords.every(naughtyWord => !code.includes(naughtyWord))) {
      return code;
    }
  }
}

export default useGenerateClassCode;