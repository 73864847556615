import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import { IRouteParams } from "AppRoutes";
import useSharedStyles from "components/useSharedStyles";
import { useOrganizationProfile } from "context/OrganizationProfileProvider";
import endpoints from "endpoints";
import React, { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import useSWR from "swr";
import { ISubscription } from "types/ISubscription";
import { formatCurrency } from "pages/godmode/utils/numbers";
import { accountTrackedDate } from "pages/godmode/utils/time";
import { SubscriptionsActiveIndicator } from "pages/godmode/Subscriptions/SubscriptionsActiveIndicator";
import Button from "components/ui/buttons/Button";
import { SubscriptionChangeAdminButton } from "pages/godmode/Subscriptions/change-admin/SubscriptionChangeAdminButton";
import { SubscriptionDowngradeButton } from "pages/godmode/Subscriptions/downgrade/SubscriptionDowngradeButton";
import { SubscriptionChangeDatesButton } from "pages/godmode/Subscriptions/change-dates/SubscriptionChangeDatesButton";
import { SubscriptionDeleteButton } from "pages/godmode/Subscriptions/delete/SubscriptionDeleteButton";
import ChangeStudentLimitButton from "./components/ChangeStudentLimitButton";
import OrganizationPlanUpgradeModal from "./OrganizationPlanUpgradeModal";
import { IOrganizationProfileNote } from "types/IOrganizationProfile";
import OrganizationProfileActivityTimeline from "./components/OrganizationProfileActivityTimeline";
import OrganizationProfileNoteDialog from "./components/dialogs/OrganizationProfileNoteDialog";
import useDialogState from "hooks/useDialogState";

export const OrganizationProfileDetailSubscriptionTab: React.FC<{organizationProfileId: string | number}> = ({organizationProfileId}) => {
    const { organization_profile } = useOrganizationProfile();
    const { params } = useRouteMatch<IRouteParams>();
    const [subscription, setSubscription] = useState<ISubscription>()
    const sharedClasses = useSharedStyles()
    const { data: rawFutureSubscriptions, error} = useSWR<ISubscription[]>(endpoints?.godmode?.futureSubscriptions(organizationProfileId))
    const {data: notes, isValidating: isValidatingNotes, error: notesError, mutate: mutateNotes} = useSWR<IOrganizationProfileNote[]>(endpoints.godmode.organizationProfile.notes.organizationNotes(organizationProfileId));
    const addNoteDialogState = useDialogState()
    const [futureSubscriptions, setFutureSubscriptions] = useState<ISubscription[]>()

    useEffect(() => {
        setFutureSubscriptions(rawFutureSubscriptions)
    }, [rawFutureSubscriptions]);

    useEffect(() => {
        if (organization_profile?.current_subscription) {
            const currentSub = organization_profile?.subscriptions?.find((sub) => sub?.id === organization_profile?.current_subscription?.id)
            setSubscription(currentSub)
            return
        }

        const sortedSubs = organization_profile?.subscriptions?.sort((a, b) => {
         return new Date(b.start_date).getTime() - new Date(a.start_date).getTime()
        })
        if (!sortedSubs || sortedSubs?.length < 1) {
            return
        }
        // Set the latest subscription as the "current" one
        setSubscription(sortedSubs[0])

        return () => {
            setSubscription(undefined)
        }
    }, [organization_profile, params])

    const onSuccessChangeStart = (subscriptionId: number, newDate: string) => {
        if (!subscription) {
            return;
        }
        if (subscription.id === subscriptionId) {
            setSubscription({...subscription, start_date: newDate})
        }
        else {
            if (!futureSubscriptions) {
                return;
            }
            setFutureSubscriptions(futureSubscriptions.map((subscription) => {
                if (subscription.id === subscriptionId) {
                    subscription.start_date = newDate
                }
                return subscription
            }))
        }
    }

    const onSuccessChangeEnd = (subscriptionId: number, newDate: string) => {
        if (!subscription) {
            return;
        }
        if (subscription.id === subscriptionId) {
            setSubscription({...subscription, end_date: newDate})
        }
        else {
            if (!futureSubscriptions) {
                return;
            }
            setFutureSubscriptions(futureSubscriptions.map((subscription) => {
                if (subscription.id === subscriptionId) {
                    subscription.end_date = newDate
                }
                return subscription
            }))
        }
    }

    return (
        <Box
            style={{
                display: 'flex',
                gap: '2rem'
            }}
        >
            <Box className={`${sharedClasses?.vspacing4}`} display="flex" flexDirection="column" minWidth="65%">
                <Box>
                    <Box className={sharedClasses?.hspacing2} mb={2} display="flex" alignItems="center">
                        <Typography variant="h1">Subscription Information</Typography>
                        <Box><SubscriptionsActiveIndicator subscription={subscription} /></Box>
                    </Box>
                    <Box className={sharedClasses?.vspacing2} p={2} style={{backgroundColor: "white", border: '1px solid #eee'}}>
                        <Box className={sharedClasses?.hspacing2} display="flex" alignItems="center">
                            <Typography variant="body1"><strong>Account Admin: </strong> {organization_profile?.administrator?.name} {`(${organization_profile?.administrator?.username})`}</Typography>
                            <SubscriptionChangeAdminButton />
                        </Box>
                        <Box className={sharedClasses?.hspacing2} display="flex" alignItems="center">
                            <Typography variant="h2">{subscription?.has_curriculum ? 'Premium+' : 'Premium'}</Typography>
                            {(!subscription?.has_curriculum && subscription?.license_count && subscription?.license_count < 2) &&
                                <OrganizationPlanUpgradeModal subscription={subscription} />
                            }
                        </Box>
                        <Box>
                            <Typography>{subscription?.license_count} site{subscription?.license_count !== 1 ? 's': ''}, {formatCurrency(subscription?.contract_amount)}</Typography>
                        </Box>
                        <Box className={sharedClasses?.vspacing1}>
                            {subscription?.student_limit &&
                            <Box className={sharedClasses?.hspacing2} display="flex">
                                <ChangeStudentLimitButton subscription={subscription} onSuccess={(studentLimit: number) => {
                                    setSubscription({...subscription, student_limit: studentLimit})
                                }}
                                />
                                <Typography>Student Limit: {subscription.student_limit}</Typography>
                            </Box>
                            }
                            {subscription?.start_date &&
                            <Box className={sharedClasses?.hspacing2} display="flex">
                                <SubscriptionChangeDatesButton onSuccess={onSuccessChangeStart} subscriptionId={subscription?.id} dateType='start' />
                                <Typography>Start Date: {accountTrackedDate(subscription?.start_date)}</Typography>
                            </Box>
                            }
                            {subscription?.end_date &&
                            <Box className={sharedClasses?.hspacing2} display="flex">
                                <SubscriptionChangeDatesButton onSuccess={onSuccessChangeEnd} subscriptionId={subscription?.id} dateType='end' />
                                <Typography>End Date: {accountTrackedDate(subscription?.end_date)}</Typography>
                            </Box>
                            }
                            {subscription?.teacher?.administrator_code &&
                                <Typography>Admin Code: <strong>{subscription?.teacher?.administrator_code}</strong></Typography>
                            }
                        </Box>
                        <Box className={sharedClasses?.hspacing2} display="flex" justifyContent="flex-end">
                            <SubscriptionDowngradeButton />
                            {subscription &&
                                <SubscriptionDeleteButton subscriptionId={subscription?.id} />
                            }
                        </Box>
                    </Box>
                </Box>
                {(futureSubscriptions && futureSubscriptions?.length > 0) &&
                <>
                    <Typography variant="h1">Upcoming Renewal{futureSubscriptions?.length !== 1 ? 's' : ''}</Typography>
                    {futureSubscriptions?.map((futureSub, index) => {
                        return (
                            <Box className={sharedClasses?.vspacing2} key={index}>
                                <Box className={sharedClasses?.vspacing2}>
                                    <Box className={sharedClasses?.hspacing2} display="flex" alignItems="center">
                                        <Typography>Account Admin: {futureSub?.teacher?.name} {`(${futureSub?.teacher?.username})`}</Typography>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                        >
                                            Change
                                        </Button>
                                    </Box>
                                    <Box className={sharedClasses?.hspacing2} display="flex" alignItems="center">
                                        <Typography variant="h2">{futureSub?.has_curriculum ? 'Premium+' : 'Premium'}</Typography>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                        >
                                            Change
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Typography>{futureSub?.license_count} site{futureSub?.license_count !== 1 ? 's': ''}, {formatCurrency(futureSub?.contract_amount)}</Typography>
                                    </Box>
                                    {futureSub?.start_date &&
                                        <Box className={sharedClasses?.hspacing2} display="flex">
                                            <SubscriptionChangeDatesButton onSuccess={onSuccessChangeStart} subscriptionId={futureSub?.id} dateType='start' />
                                            <Typography>Start Date: {accountTrackedDate(futureSub?.start_date)}</Typography>
                                        </Box>
                                    }
                                    {futureSub?.end_date &&
                                        <Box className={sharedClasses?.hspacing2} display="flex">
                                            <SubscriptionChangeDatesButton onSuccess={onSuccessChangeEnd} subscriptionId={futureSub?.id} dateType='end' />
                                            <Typography>End Date: {accountTrackedDate(futureSub?.end_date)}</Typography>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                        )
                    })}
                </>
                }
            </Box>
            <Box className={sharedClasses?.vspacing4} style={{ width: '100%'}}>
                <OrganizationProfileNoteDialog mutateNotes={mutateNotes} dialogState={addNoteDialogState} />
                <Box className={sharedClasses?.vspacing2}>
                    <Typography variant="h1">Activity Timeline</Typography>
                    <Button
                        startIcon={<FontAwesomeIcon icon={faPlus} />}
                        variant="contained"
                        color="blue"
                        onClick={() => addNoteDialogState.handleOpen()}
                    >
                        Add Note
                    </Button>
                </Box>
                <OrganizationProfileActivityTimeline
                    notes={notes}
                    isValidatingNotes={isValidatingNotes}
                    notesError={notesError}
                    mutateNotes={mutateNotes}
                />
            </Box>
        </Box>
    )
}