import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, List, ListItem, ListItemIcon, ListItemText, Paper } from "@material-ui/core";
import { IStudentRowData } from "./IStudentRowData";
import React from "react";

interface StudentActionConfirmationListProps {
  students: IStudentRowData[];
  selectedStudents?: IStudentRowData[];
  onSelectStudent?: (student: IStudentRowData, selected: boolean) => void;
  showCurrentClass?: boolean;
}

const StudentActionConfirmationList: React.VFC<StudentActionConfirmationListProps> = ({ students, selectedStudents, onSelectStudent, showCurrentClass }) => {
  return <Paper component={List} variant="outlined">
    {students.map(studentData => {
      const selected = selectedStudents?.some(({ id }) => id === studentData.id);
      return <ListItem
        selected={selected}
        onClick={onSelectStudent ? () => onSelectStudent(studentData, !selected) : undefined}
        button={!!onSelectStudent as any}
        key={studentData.id}
      >
        <ListItemIcon>
          {!onSelectStudent && <FontAwesomeIcon icon={faUser} size="2x" />}
          {!!onSelectStudent && <Checkbox checked={selected} />}
        </ListItemIcon>
        <ListItemText primary={studentData.name} secondary={showCurrentClass ? (studentData.klass !== undefined ? <><span style={{ fontWeight: 'bold' }}>Current class:</span> {studentData.klass?.klass_name}</> : "Not in a class") : ""} />
      </ListItem>
    })}
  </Paper>
}

export default StudentActionConfirmationList;