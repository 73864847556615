import {
  Box,
  CircularProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  TableSortLabel,
  InputAdornment,
  useTheme,
  Tooltip,
  TablePagination,
  Typography
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import useSWR from "swr";
import endpoints from "endpoints";
import { ITeacherData } from "types/ITeacherData";
import useSharedStyles from "components/useSharedStyles";
import React, { useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEdit, faExchangeAlt, faLink, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "components/ui/buttons/Button";
import DeleteStudentsDialog from "../../components/dialogs/class/DeleteStudentsDialog";
import { IStudentRowData } from "./IStudentRowData";
import TransferStudentsDialog from "../../components/dialogs/class/TransferStudentsDialog";
import EditStudentDialog from "../../components/dialogs/class/EditStudentDialog";
import { getStudentPasswordIcon } from "./studentLoginIcons";
import { getMatDirFromSortDir } from "utils/sort";
import PageHeader from "components/ui/PageHeader";
import TextField from "components/ui/TextField";
import { ELeadState } from "types/IStudentSchoolProfile";
import useDialogState from "hooks/useDialogState";
import { useHistory } from "react-router-dom";
import { routes } from "routes";
import {StudentInstructionsDialog} from "../../components/dialogs/StudentInstructionsDialog";

interface StudentsTableProps {
  klass_id?: number | undefined;
  embed?: boolean;
  showPasswordPictures?: boolean;
  onAddStudents: (targetClassId?: number) => any
}

const useViewStudentsListStyles = makeStyles(theme => ({
  checkboxRoot: {
    padding: '0 !important'
  }
}));

const StudentsTable: React.VFC<StudentsTableProps> = ({ klass_id, onAddStudents, showPasswordPictures = false, embed = false }) => {
  const { data: teacherData, error, revalidate } = useSWR<ITeacherData>(endpoints.teacherInit);
  const loading = !teacherData && !error;
  const history = useHistory();
  const sharedClasses = useSharedStyles();
  const [showDeleteStudentsDialog, setShowDeleteStudentsDialog] = useState(false);
  const [showMoveStudentsDialog, setShowMoveStudentsDialog] = useState(false);
  const [showEditStudentDialog, setShowEditStudentDialog] = useState(false);
  const [oneStudentTarget, setOneStudentTarget] = useState<IStudentRowData>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const classes = useViewStudentsListStyles();
  const theme = useTheme();

  const [sort, setSort] = useState<{
    key: string,
    prop: (student: IStudentRowData) => string,
    dir: number
  }>(embed ? {
    key: 'name',
    prop: student => student.name,
    dir: -1
  } : {
    key: 'class',
    prop: student => student.klass?.klass_name || '',
    dir: -1
  });
  const [studentFilter, setStudentFilter] = useState('');

  const classFilteredStudents = useMemo(() => {
    return teacherData?.students
      .filter(({ klass_id: _klass_id }) => (!klass_id || klass_id === _klass_id))
      .map(student => ({
        ...student,
        klass: teacherData?.klasses.find(({ id }) => id === student.klass_id),
        teacher: teacherData?.teachers.find(({ id }) => id === student.teacher_id)
      }))
  }, [klass_id, teacherData, studentFilter]);

  const sortedStudents = useMemo(() => {
    return classFilteredStudents?.slice()
      .sort((studentA, studentB) => (sort.prop(studentB).localeCompare(sort.prop(studentA))) * sort.dir)
      .filter(({ name }) => name.toLocaleLowerCase().includes(studentFilter))
  }, [
    classFilteredStudents,
    sort
  ]);

  const [selectedStudentIds, setSelectedStudentIds] = useState<{ [key: number]: boolean }>({});
  const selectedStudentProfilesArray = useMemo(() => {
    return classFilteredStudents?.filter(({ id }) => selectedStudentIds[id]) || [];
  }, [classFilteredStudents, selectedStudentIds]);
  const currentClass = useMemo(() => {
    return teacherData!.klasses.find(({ id }) => id === klass_id)!;
  }, [teacherData, klass_id]);
  const studentInstructionsDialogState = useDialogState();

  const handleSelectStudent = (student: IStudentRowData, selected: boolean) => {
    setSelectedStudentIds(ids => ({ ...ids, [student.id]: selected }))
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (loading) {
    return <Box display="flex" alignItems="center" justifyContent="center">
      <CircularProgress />
    </Box>
  }

  return <Box className={sharedClasses.vspacing2}>
    {!embed && <PageHeader title="My Students" inAppBar></PageHeader>}
    <DeleteStudentsDialog
      open={showDeleteStudentsDialog}
      students={showDeleteStudentsDialog ? (oneStudentTarget ? [oneStudentTarget] : selectedStudentProfilesArray) : []}
      onClose={() => { setShowDeleteStudentsDialog(false); setOneStudentTarget(undefined); }}
    />
    <TransferStudentsDialog
      open={showMoveStudentsDialog}
      students={showMoveStudentsDialog ? (oneStudentTarget ? [oneStudentTarget] : selectedStudentProfilesArray) : []}
      onClose={() => { setShowMoveStudentsDialog(false); setOneStudentTarget(undefined); }}
      didMoveStudents={() => setSelectedStudentIds({})}
    />
    <EditStudentDialog
      open={showEditStudentDialog}
      studentToEdit={oneStudentTarget || selectedStudentProfilesArray[0]}
      onClose={() => { setShowEditStudentDialog(false); setOneStudentTarget(undefined); }}
    />
    {currentClass && <StudentInstructionsDialog
      klass={currentClass}
      {...studentInstructionsDialogState}
    />}
    <Box display="flex" flexDirection="row" pb={1} className={sharedClasses.hspacing2}>
      <Button
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        variant="contained"
        color="primary"
        disableElevation={false}
        onClick={() => onAddStudents(klass_id)}
      >
        Add Students
      </Button>
      {currentClass && <Button
        startIcon={<FontAwesomeIcon icon={faDownload} />}
        variant="contained"
        color="orange"
        onClick={studentInstructionsDialogState.handleOpen}
      >Student Instructions</Button>}
      <Button
        variant="contained"
        color="orange"
        onClick={() => {
          history.push(routes.parentConnect)
        }}
      >
        Invite Families
      </Button>
      <Button
        startIcon={<FontAwesomeIcon icon={faExchangeAlt} />}
        variant="contained"
        color="purple"
        disabled={selectedStudentProfilesArray.length === 0}
        onClick={() => setShowMoveStudentsDialog(true)}
      >
        Transfer Selected Students
      </Button>
      <Button
        startIcon={<FontAwesomeIcon icon={faTrash} />}
        variant="contained"
        color="red"
        disabled={selectedStudentProfilesArray.length === 0}
        onClick={() => setShowDeleteStudentsDialog(true)}
      >
        Delete Selected Students
      </Button>
    </Box>
    <Box display="flex" flexDirection="row" pb={1} className={sharedClasses.hspacing2}>
      <TextField
        autoFocus
        placeholder="Search Students"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon={faSearch} />
            </InputAdornment>
          ),
        }}
        value={studentFilter}
        onChange={e => setStudentFilter(e.target.value.toLocaleLowerCase())}
        style={{
          width: 400
        }}
      />
    </Box>
    {sortedStudents && sortedStudents.length > 0 && <TableContainer {...{ component: Paper, variant: 'outlined' }}>
        <Table>
          <TableHead style={{ backgroundColor: theme.palette.divider }}>
            <TableRow>
              <TableCell style={{ background: 0 }}>
                <Checkbox
                  classes={{ root: classes.checkboxRoot }}
                  onChange={e => {
                    if (e.target.checked) {
                      setSelectedStudentIds(sortedStudents.reduce((prev, cur) => ({ ...prev, [cur.id]: true }), {}))
                    } else {
                      setSelectedStudentIds({});
                    }
                  }}
                  checked={Object.keys(selectedStudentIds).filter(id => sortedStudents.map(({ id }) => id).includes(parseInt(id)) && selectedStudentIds[parseInt(id)]).length === sortedStudents?.length}
                />
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sort?.key === 'name'}
                  direction={getMatDirFromSortDir(sort?.dir)}
                  onClick={() => setSort(sort => ({ key: 'name', prop: student => student.name, dir: sort?.key === 'name' ? sort.dir * -1 : 1 }))}
                >
                  <Typography variant="subtitle1">Name</Typography>
                </TableSortLabel>
              </TableCell>
              {showPasswordPictures && <TableCell>Picture Password</TableCell>}
              {embed && <TableCell>Parent Code</TableCell>}
              {/* <TableCell>
                Teacher
              </TableCell> */}
              {!embed && <TableCell>
                <TableSortLabel
                  active={sort?.key === 'class'}
                  direction={getMatDirFromSortDir(sort?.dir)}
                  onClick={() => setSort(sort => ({
                    key: 'class',
                    prop: student => teacherData?.klasses.find(({ id }) => id === student.klass_id)?.klass_name || '',
                    dir: sort?.key === 'class' ? sort.dir * -1 : 1
                  }))}
                >
                  <Typography variant="subtitle1">Class</Typography>
                </TableSortLabel>
              </TableCell>}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedStudents?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(student => <StudentsTableRow
              key={student.id}
              student={student}
              selected={selectedStudentIds[student.id]}
              showClass={!embed}
              showPasswordPictures={showPasswordPictures}
              onSelectStudent={handleSelectStudent}
              showParentCode={embed}
              onDeleteStudent={student => { setOneStudentTarget(student); setShowDeleteStudentsDialog(true); }}
              onMoveStudent={student => { setOneStudentTarget(student); setShowMoveStudentsDialog(true); }}
              onEditStudent={student => { setOneStudentTarget(student); setShowEditStudentDialog(true); }}
            />
            )}
          </TableBody>
        </Table>
      <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={sortedStudents?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>}

    {classFilteredStudents?.length === 0 &&
      <Alert
        severity="info"
        action={<Button color="inherit" size="small" onClick={() => onAddStudents(klass_id)}>Add students</Button>}
      >You don't have any students in this class.</Alert>
    }

    {classFilteredStudents !== undefined && classFilteredStudents?.length > 0 && sortedStudents?.length === 0 &&
      <Alert
        severity="info"
      >We couldn't find any students with this filter.</Alert>
    }

    {teacherData?.students.length === 0 &&
      <Alert
        severity="info"
        action={<Button color="inherit" size="small" onClick={() => onAddStudents(klass_id)}>Add students</Button>}
      >You don't have any students added yet.</Alert>
    }

    {error && <Alert severity="error"
      action={
        <Button
          onClick={() => revalidate()}
          color="inherit"
          size="small"
        >Try again</Button>
      }
    >There was an error retrieving your students.</Alert>}
  </Box>
}

interface StudentsTableRowProps {
  student: IStudentRowData;
  selected?: boolean;
  showTeacher?: boolean;
  showClass?: boolean;
  showPasswordPictures?: boolean;
  showParentCode?: boolean;
  onSelectStudent?: (student: IStudentRowData, selected: boolean) => void;
  onDeleteStudent?: (student: IStudentRowData) => void;
  onMoveStudent?: (student: IStudentRowData) => void;
  onEditStudent?: (student: IStudentRowData) => void;
}

const StudentsTableRow: React.VFC<StudentsTableRowProps> = ({
  student,
  selected,
  showTeacher,
  showClass,
  showPasswordPictures,
  showParentCode,
  onSelectStudent = (...args: any[]) => { },
  onDeleteStudent = (...args: any[]) => { },
  onMoveStudent = (...args: any[]) => { },
  onEditStudent = (...args: any[]) => { }
}) => {
  const classes = useViewStudentsListStyles();
  const sharedClasses = useSharedStyles();
  const theme = useTheme();

  return (<>
    <TableRow
      key={student.id}
      selected={selected}
    >
      <TableCell style={{ width: 0 }}>
        <Checkbox
          classes={{ root: classes.checkboxRoot }}
          onChange={() => onSelectStudent(student, !selected)}
          checked={!!selected}
        />
      </TableCell>

      <TableCell>
        {student.name}
        {student.is_teacher && <Box whiteSpace="nowrap" bgcolor={theme.palette.purple.main} ml={1} color="white" display="inline" borderRadius={16} py={0.5} px={1}>Teacher Profile</Box>}
        {student.lead?.state === ELeadState.linked && <>&nbsp;<Tooltip placement="top" title="This student has a linked home profile"><span><FontAwesomeIcon color={theme.palette.blue.main} icon={faLink} /></span></Tooltip></>}
      </TableCell>

      {showPasswordPictures && <TableCell>
        <img style={{ height: 32 }} src={`images/picture-passwords/${getStudentPasswordIcon(student.student_code)}.png`} alt={getStudentPasswordIcon(student.student_code)} />
      </TableCell>}

      {showParentCode && <TableCell>
        {student.student_code}
      </TableCell>}

      {showTeacher && <TableCell>
        {student.teacher?.name}
      </TableCell>}

      {showClass && <TableCell>
        {student.klass?.klass_name}
      </TableCell>}

      <TableCell style={{ width: '1%' }} onClick={e => e.stopPropagation()}>
        <Box display="flex" flexDirection="row" className={sharedClasses.hspacing2}>
          <Button
            variant="contained"
            color="orange"
            startIcon={<FontAwesomeIcon icon={faEdit} />}
            onClick={() => onEditStudent(student)}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="red"
            startIcon={<FontAwesomeIcon icon={faTrash} />}
            onClick={() => onDeleteStudent(student)}
          >
            Delete
          </Button>
        </Box>
      </TableCell>
    </TableRow>
  </>)
}

export default StudentsTable;
