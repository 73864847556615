import { Route } from "react-router-dom"
import { routes } from "routes"
import { CreateQuoteStart } from "./CreateQuoteStart"
import { ViewQuote } from "./ViewQuote"
import { QuoteRenewal } from "./QuoteRenewal"
import SelectLocationTypePage from "./SelectLocationTypePage";
import SelectOrgTypePage from "./SelectOrgTypePage";
import FindSchoolPage from "./FindSchoolPage";
import CreateQuotePage from "./CreateQuotePage";
import {OrganizationSelectionProvider} from "../../../context/OrganizationSelectionProvider";
import FindDistrictPage from "./FindDistrictPage";
import React from "react";

export const QuotesIndex: React.VFC = () => {
  return <>
    <OrganizationSelectionProvider>
      <Route
        exact
        path={routes.sales.quotes.create.new}
        component={CreateQuoteStart}
      />
      <Route
        exact
        path={routes.sales.quotes.create.selectLocation}
        component={SelectLocationTypePage}
      />
      <Route
        exact
        path={routes.sales.quotes.create.selectOrgType}
        component={SelectOrgTypePage}
      />
      <Route
        exact
        path={routes.sales.quotes.create.findSchool}
        component={FindSchoolPage}
      />
      <Route
        exact
        path={routes.sales.quotes.create.findDistrict}
        component={FindDistrictPage}
      />
      <Route
        exact
        path={routes.sales.quotes.create.form}
        component={CreateQuotePage}
      />
      <Route
        exact
        path={routes.sales.quotes.createRenewal()}
        component={CreateQuoteStart}
      />
    </OrganizationSelectionProvider>
    <Route
      path={routes.sales.quotes.view()}
      component={ViewQuote}
    />
    <Route
      path={routes.sales.quotes.renewal()}
      component={QuoteRenewal}
    />
  </>
}