import Cookies from 'universal-cookie';
import useNavStyles from "./useNavStyles";
import { AppBar as MatAppBar, Box, Hidden, IconButton, Menu, Toolbar } from '@material-ui/core';
import { ClassesProp } from "components/ui/ClassesProp";
import Button from "components/ui/buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCaretDown, faCog, faQuestionCircle, faSignOutAlt, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import useSharedStyles from "components/useSharedStyles";
import React, { useState, useRef } from "react";
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { routes } from "routes";
import useCurrentUser from 'loaders/useCurrentUser';
import GettingStartedDialog from 'components/dialogs/onboarding/GettingStartedDialog';
import { useContext } from 'react';
import { NavContext } from 'context/NavProvider';
import MenuItem from 'components/ui/MenuItem';
import { useRedirectToLogin } from 'context/AuthProvider';
import { faEmpire } from '@fortawesome/free-brands-svg-icons';
import { setSessionTokenCookie } from 'pages/login/loginUtils';
import { PlanChip } from 'components/ui/PlanChip';

const cookies = new Cookies();

const AppBar: React.VFC<ClassesProp<typeof useNavStyles>> = (props) => {
  const { currentUser } = useCurrentUser();
  const { redirectToLogin } = useRedirectToLogin();
  const sharedClasses = useSharedStyles();
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const accountMenuAnchorRef = useRef(null);
  const [showGettingStartedDialog, setShowGettingStartedDialog] = useState(false);
  const { navPortal, drawerState: [drawerOpen, setDrawerOpen] } = useContext(NavContext);
  const history = useHistory();
  const classes = useNavStyles(props);

  const handleUnimpersonate = () => {
    setAccountMenuOpen(false)
    const kodable_kode = cookies.get('admin_kodable_kode');

    if (kodable_kode) {
      setSessionTokenCookie(kodable_kode)
    }

    cookies.remove('admin_kodable_kode', {
      path: '/',
      domain: process.env.REACT_APP_LOCAL
        ? 'localhost'
        : '.kodable.com',
    });

    localStorage.clear();
    history.push(routes.godmode.index)
    window.location.reload();
  }

  return (
    <>
    {
      currentUser && !currentUser?.is_parent && <GettingStartedDialog
        open={showGettingStartedDialog}
        onClose={() => setShowGettingStartedDialog(false)}
      />
    }
      <Menu
        anchorEl={accountMenuAnchorRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={accountMenuOpen}
        onClose={() => setAccountMenuOpen(false)}
      >
        {!currentUser?.needsTeacherOnboarding && <MenuItem
          onClick={() => setAccountMenuOpen(false)}
          {...{
            component: RouterLink,
            to: routes.settings.index
          }}
        >
          <FontAwesomeIcon icon={faCog} />&nbsp;&nbsp;Account
        </MenuItem>}
        {
          cookies.get('admin_kodable_kode') && <MenuItem onClick={handleUnimpersonate}>
            Unimpersonate
          </MenuItem>
        }
        {
          currentUser?.is_god && <MenuItem onClick={() => {
            history.push(routes.godmode.index);
          }}>
            <FontAwesomeIcon icon={faEmpire} />&nbsp;&nbsp;V2 Godmode
          </MenuItem>
        }
        <MenuItem
          {...{
            component: 'a',
            href: 'http://support.kodable.com/en/',
            target: '_blank'
          }}
        >
          <FontAwesomeIcon icon={faQuestionCircle} />&nbsp;&nbsp;Support
        </MenuItem>
        <MenuItem
          onClick={() => redirectToLogin(true)}
        >
          <FontAwesomeIcon icon={faSignOutAlt} />&nbsp;&nbsp;Log out
        </MenuItem>
      </Menu>
      <MatAppBar style={{borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}} position="fixed" color="default" elevation={0} className={classes.appBar}>
        <Toolbar>
          {currentUser && <Hidden mdUp>
            <Box mr={1}>
              <IconButton
                color="inherit"
                onClick={() => setDrawerOpen(!drawerOpen)}
              >
                <FontAwesomeIcon icon={faBars} />
              </IconButton>
            </Box>
          </Hidden>}
          <Box minWidth={[0, 200]} mr={1}>
            <img
              style={{
                height: 33
              }}
              src="/images/KodableLogo.png"
              alt="Kodable Logo"
            ></img>
          </Box>
          <Box flexGrow={1} {...{ ref: navPortal }} />
          <Box className={sharedClasses.hspacing2}>
            {currentUser &&
              <>
                <PlanChip
                  plan={currentUser?.plan}
                  isParent={currentUser.is_parent}
                  hasCurriculum={currentUser.has_curriculum}
                />
                <Button
                  startIcon={<FontAwesomeIcon icon={faUserCircle} />}
                  endIcon={<FontAwesomeIcon icon={faCaretDown} />}
                  color="inherit"
                  onClick={() => setAccountMenuOpen(true)}
                  ref={accountMenuAnchorRef}
                >
                  {currentUser?.name || 'Account'}
                </Button>
              </>
            }
            {
              !currentUser && <Button
                variant="contained"
                color="green"
                onClick={() => redirectToLogin()}
              >
                Log In
              </Button>
            }
          </Box>
        </Toolbar>
      </MatAppBar>
    </>
  );
}

export default AppBar;