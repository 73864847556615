import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Link, makeStyles, Step, StepButton, Stepper, Typography } from "@material-ui/core";
import PageContainer from "components/ui/PageContainer"
import endpoints from "endpoints";
import { Form, Formik, FormikProps } from "formik";
import { justFetch } from "mutations/mutate";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Prompt, Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { routes } from "routes";
import { defaultOrderRequest, IOrderRequest } from "../IOrderRequest";
import { AddBillingContact } from "./AddBillingContact";
import { ConfigurePayment } from "./ConfigurePayment";
import { ConfigurePlan } from "./ConfigurePlan";
import { SetupAdministrator } from "./SetupAdministrator";
import * as Yup from 'yup';
import { ConfirmOrder } from "./ConfirmOrder";
import { Alert } from "@material-ui/lab";
import { useAskBeforeClosingPage } from "utils/useAskBeforeClosingPage";
import useCurrentUser from "loaders/useCurrentUser";
import { UploadPurchaseOrder } from "./UploadPurchaseOrder";
import { SubmitOrderSuccess } from "./SubmitOrderSuccess";
import queryString from 'query-string';
import { useSubmitOrderFormHooks } from "./useSubmitOrderFormHooks";
import { IQuote } from "pages/sales/IQuote";
import useSWR from "swr";
import { getPlanForRenewal, ISalesProfile } from "pages/settings/teacher/ISalesProfile";
import { parse } from "date-fns";
import { OrganizationSelectionProvider } from "context/OrganizationSelectionProvider";
import OrderSelectLocationTypePage from "./OrderSelectLocationTypePage";
import OrderFindSchoolPage from "./OrderFindSchoolPage";
import OrderFindDistrictPage from "./OrderFindDistrictPage";
import OrderSelectOrgTypePage from "./OrderSelectOrgTypePage";
import { billingFromSalesProfile } from "pages/godmode/Users/Teachers/ViewUser/account/BillingContactBlock";

declare global {
  interface Window {
    Intercom: any;
  }
}

export const today = new Date();

interface OrderStep {
  label: string;
  title: string;
  pathname: string;
  extraPathnames?: string[];
}

export const submitOrderSteps: OrderStep[] = [
  
  // location
  {
    label: 'Location Details',
    title: 'Place Your Order',
    pathname: routes.sales.orders.submit.selectLocation,
    extraPathnames: [
      routes.sales.orders.submit.selectOrgType, 
      routes.sales.orders.submit.findSchool, 
      routes.sales.orders.submit.findDistrict]
  }, 
  // plan
  // num sites
  {
    label: 'Order Details',
    title: 'Place Your Order',
    pathname: routes.sales.orders.submit.plan
  },
  // org name, billing contact name/address/email/phone
  {
    label: 'Billing Info',
    title: 'Place Your Order',
    pathname: routes.sales.orders.submit.billingContact
  },
  {
    label: 'Pay With a PO',
    title: 'Place Your Order',
    pathname: routes.sales.orders.submit.purchaseOrder
  },
  // name
  // email address
  {
    label: 'Account Details',
    title: 'Place Your Order',
    pathname: routes.sales.orders.submit.administrator
  },
  // {
  //   label: 'Payment',
  //   pathname: routes.sales.orders.submit.payment
  // },
  {
    label: 'Confirm',
    title: 'Place Your Order',
    pathname: routes.sales.orders.submit.confirmation
  },
];

export const omitLicenseHolderSteps = submitOrderSteps.filter(({ pathname }) => pathname !== routes.sales.orders.submit.administrator);

const useSubmitOrderIndexStyles = makeStyles(theme => ({
  stepperRoot: {
    width: '100%'
  },
  stepperIconRoot: {
    // fontSize: 50
  }
}));

interface PlanDetails {
  has_curriculum?: boolean;
  num_sites?: number;
  site_names?: string[];
  school_ids?: number[];
}

export const SubmitOrderIndex: React.VFC = () => {
  const [submitError, setSubmitError] = useState<false | string>(false);
  const [submitErrorSentryId, setSubmitErrorSentryId] = useState<false | string>(false);
  const [didSubmit, setDidSubmit] = useState(false);
  const history = useHistory();
  const classes = useSubmitOrderIndexStyles();
  const { pathname } = useLocation();
  useAskBeforeClosingPage();
  const { currentUser } = useCurrentUser();

  const quoteSecretCode = useMemo(() => queryString.parse(window.location.search)?.quote, []);
  const renewalTeacherId = useMemo(() => queryString.parse(window.location.search)?.renew, []);
  const { data: quote, error: quoteError } = useSWR<IQuote>(quoteSecretCode ? endpoints.quote(quoteSecretCode as string) : null);
  const { data: salesProfile, error: salesProfileError } = useSWR<ISalesProfile>((quote?.teacher_id || renewalTeacherId || currentUser?.id) ? endpoints.salesProfile(quote?.teacher_id || renewalTeacherId as string || currentUser?.id) : null)
  const planForRenewal = salesProfile && getPlanForRenewal(salesProfile);
  const showLocationStep = !quoteSecretCode;
  const _submitOrderSteps = ((planForRenewal && false)? omitLicenseHolderSteps : submitOrderSteps).filter((step, index)=>showLocationStep || index > 0);
  const activeStep = useMemo(() => _submitOrderSteps.findIndex(step => step.pathname === pathname || step.extraPathnames?.includes(pathname)), [pathname, _submitOrderSteps]);
  const formikRef = useRef<FormikProps<IOrderRequest>>(null);
  const [godUserOverride, setGodUserOverride] = useState<boolean>(false);



  const convertToUTC = (date: Date | undefined) => {
    if (!date) {
      return undefined;
    }
    // Assuming 'date' is a JavaScript Date object
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  }

  const handleSubmit = (values: IOrderRequest) => {
    return justFetch(endpoints.orders, 'POST', {
      ...quote,
      ...values, // values override quote
      activation_date: values.activation_date !== '' ? convertToUTC(values.activation_date) : undefined,
      organization_profile_id: quote?.organization_profile_id || currentUser?.organization_profile?.id,
      god_user_override: godUserOverride,
    })
      .then(res => {
        setDidSubmit(true);
        if (!res.ok) {
          res.json().then(body => {
            setSubmitError(body.message || body.error);
            setSubmitErrorSentryId(body.sentry_issue_id);

          });
        } else {
          history.push(routes.sales.orders.submit.success, values);
        }
      })
      .catch(() => {
        setSubmitError('An unknown error occurred or you are offline. Please try submitting again.')
      });
  }
  
  // Handles adding the Intercom chat bubble
  useEffect(() => {
    if (window && window.Intercom && typeof window?.Intercom == 'function') {
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: process.env.REACT_APP_INTERCOM_ID
      });
    }
  }, [])


  const waitingOnQuote = !quote && !!quoteSecretCode;
  const waitingOnSalesProfile = !salesProfile && (waitingOnQuote || !!quote?.teacher_id || !!currentUser?.id);

  const initialValues = useMemo(() => {
    if (waitingOnQuote || waitingOnSalesProfile) {
      return null;
    }

    let license_holder: {};
    let plan_details: PlanDetails = {};
    let activation_date: {};
    let billing = billingFromSalesProfile(salesProfile);
    let godModeUpgrade = false;

    let upgradeTeacherName = queryString.parse(window.location.search)?.upgrade_teacher_name;
    let upgradeTeacherUsername = queryString.parse(window.location.search)?.upgrade_teacher_username;
    
    if (quote) {
      license_holder = {
        license_holder_id: quote.teacher_id,
        license_holder_name: quote.teacher_name! || " ",
        license_holder_email: quote.teacher_username! || " ",
      }
    } else if (salesProfile?.license_holder) {
      license_holder = {
        license_holder_id: salesProfile?.license_holder.id,
        license_holder_name: salesProfile?.license_holder.name || " ",
        license_holder_email: salesProfile?.license_holder.username,
      }
    } else if (upgradeTeacherName && upgradeTeacherUsername) {
      license_holder = {
        license_holder_name: upgradeTeacherName,
        license_holder_email: upgradeTeacherUsername // username is email
      }
      godModeUpgrade = true;
    } else if (currentUser) {
      license_holder = {
        license_holder_name: currentUser.name || " ",
        license_holder_email: currentUser.username
      }
    } else {
      license_holder = {
        license_holder_name: defaultOrderRequest.license_holder_name || " ",
        license_holder_email: defaultOrderRequest.license_holder_email
      }
    }

    plan_details.site_names = []
    plan_details.school_ids = []
    if (quote) {
      plan_details = {
        has_curriculum: quote.has_curriculum,
        num_sites: quote.num_sites,
        site_names: [...(quote.site_names ?? [])],
      }
      // fill in site_ids from planForRenewal, based on names from quote. quote doesnt keep school_ids
      if (planForRenewal && plan_details.site_names)
        {
          const siteNameToIdMap: { [key: string]: number } = { };
          siteNameToIdMap[''] = 0;
          planForRenewal.licenses.forEach(license => {
            if (license.site_name)
              siteNameToIdMap[license.site_name] = license.school_id || 0;
          });      
          plan_details.school_ids = plan_details.site_names.map(siteName => siteNameToIdMap[siteName] ?? 0);
        }
    } else if (planForRenewal) {
      const siteNames = planForRenewal.licenses.map(license => license.site_name || '');
      const schoolIds = planForRenewal.licenses.map(license => license.school_id || 0);
      plan_details = {
        has_curriculum: planForRenewal.has_curriculum,
        num_sites: planForRenewal.num_sites,
        site_names: siteNames,
        school_ids: schoolIds
      }
    } else {
      plan_details = {
        has_curriculum: queryString.parse(window.location.search)?.curriculum !== undefined
      }
    }
    const minActivationDate = planForRenewal && planForRenewal.active ? parse(planForRenewal.end_date, 'yyyy-LL-dd', new Date()) : today;

    activation_date = {
      activation_date: '',
      min_activation_date: minActivationDate
    }

    return {
      ...license_holder,
      ...plan_details,
      ...activation_date,
      organization_name: salesProfile?.organization_name || quote?.organization_name || '',
      quote_request_v2_id: quote?.id,
      ...billing,
      billing_contact_address_code: billing?.billing_contact_address_zip || '', // billing uses zip, order form uses code
      billing_contact_first_name: salesProfile?.billing_contact_name?.split(' ')[0] || quote?.contact_name?.split(' ')[0] || '',
      billing_contact_last_name: salesProfile?.billing_contact_name?.split(' ').slice(1).join('') || quote?.contact_name.split(' ').slice(1).join('') || '',
      billing_contact_email: salesProfile?.billing_contact_email || quote?.contact_email || '',
      billing_contact_phone: salesProfile?.billing_contact_phone || '',
      term_months: 12,
      godModeUpgrade
    }
  }, [waitingOnQuote || waitingOnSalesProfile]);

  if (!initialValues) {
    if (salesProfileError || quoteError) {
      return <PageContainer variant="centered">
        <Alert severity="error">We couldn't load the quote you are trying to order from. Please contact support@kodable.com for more information.</Alert>
      </PageContainer>
    }

    return <PageContainer variant="centered">
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    </PageContainer>
  }

  if (salesProfile?.license_holder && !salesProfile?.license_holder?.eligible_for_order) {
    return <PageContainer variant="centered">
    <Alert severity="warning">You can't submit this form right now. Please contact support@kodable.com for more information.</Alert>
  </PageContainer>
  }

  const handleErrorClose = () => {
    setGodUserOverride(false);
    setSubmitError(false);
  };

  const handleGodUserOverrideAndResubmit = () => {
    setSubmitError(false);
    setGodUserOverride(true);
    if (formikRef.current) {
      formikRef.current.submitForm();
    }   
  };
  

  return <Formik
    innerRef={formikRef}
    enableReinitialize
    initialValues={{
      ...defaultOrderRequest,
      ...initialValues
    }}
    validateOnMount
    onSubmit={handleSubmit}
    validationSchema={Yup.object<IOrderRequest>({
      has_curriculum: Yup.bool().required(),
      num_sites: Yup.number().required().min(1).max(25),
      organization_name: Yup.string().required('Please enter the name of your organization'),

      billing_contact_first_name: Yup.string().required('Please enter the name of your billing contact'),
      billing_contact_last_name: Yup.string().required('Please enter the name of your billing contact'),

      billing_contact_address_street: Yup.string().required('Please enter a billing address'),
      billing_contact_address_street2: Yup.string(),
      billing_contact_address_city: Yup.string().required('Please enter a city'),
      billing_contact_address_province: Yup.string().required('Please enter a state / province'),
      billing_contact_address_code: Yup.string().required('Please enter a postal code'),
      billing_contact_address_country: Yup.string().required('Please enter a country'),

      billing_contact_email: Yup.string().email().required('Please enter an email for your billing contact'),
      billing_contact_phone: Yup.string().required('Please enter a phone number for your billing contact'),

      license_holder_name: Yup.string().required('Please enter the name of the person managing your Kodable subscription'),
      license_holder_email: Yup.string().email().required('Please enter an email address for the person managing your Kodable subscription'),

      agreed_to_terms: Yup.bool().required().equals([true]),

      purchase_order_number: Yup.string().when('purchase_order_file_id', {
        is: (field: string | undefined) => (field?.length || 0) > 0,
        then: Yup.string().required('Please enter a purchase order number')
      })
    })}
  >
    <Form>
      <Route
        path={routes.sales.orders.submit.success}
        component={SubmitOrderSuccess}
      />
      
      {pathname !== routes.sales.orders.submit.success && <PageContainer variant="centered">
        <Box style={{position: 'relative'}}>
          <Prompt
            when={!didSubmit}
            message={location => location.pathname.includes(routes.sales.orders.index) || 'Are you sure you want to leave?'}
          />
          <Dialog open={!!submitError} fullWidth>
            <DialogTitle style={{ textAlign: 'left' }}>There was an error submitting your order</DialogTitle>
            <DialogContent>
              <Alert severity="error">{submitError}</Alert>
              {submitErrorSentryId && <>Share the following code with <Link href="mailto:support@kodable.com">support@kodable.com</Link> to help them diagnose the issue: {submitErrorSentryId}</>}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleErrorClose} variant="outlined">
                Close
              </Button>
              {currentUser?.is_god && <Button onClick={handleGodUserOverrideAndResubmit} variant="outlined">
                God User Override and ReSubmit
              </Button>}
            </DialogActions>
          </Dialog>
          <Box pb={1}>
            <Typography variant="h1" align="center">{_submitOrderSteps[activeStep]?.title}</Typography>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="center" width="100%" borderBottom="1px solid #eeeeee" mb={4}>
            <Stepper classes={{ root: classes.stepperRoot }} activeStep={activeStep} alternativeLabel>
              {
                _submitOrderSteps.map((step, index) => <Step key={index}>
                  <StepButton disableRipple disabled={activeStep < index} completed={activeStep > index} onClick={() => history.push(step.pathname)}>{step.label}</StepButton>
                </Step>)
              }
            </Stepper>
          </Box>
          <OrganizationSelectionProvider>

            <Route
              exact
              path={routes.sales.orders.submit.selectLocation}
              component={OrderSelectLocationTypePage}
            />
            <Route
              exact
              path={routes.sales.orders.submit.selectOrgType}
              component={OrderSelectOrgTypePage}
            />
            <Route
              exact
              path={routes.sales.orders.submit.findSchool}
              component={OrderFindSchoolPage}
            />
            <Route
              exact
              path={routes.sales.orders.submit.findDistrict}
              component={OrderFindDistrictPage}
            />
            <Route
              render={(props) => (
                <ConfigurePlan {...props} allowPreviousButton={showLocationStep} salesProfile={salesProfile}/>
              )} 
              path={routes.sales.orders.submit.plan}
            />
            <Route
              component={AddBillingContact}
              path={routes.sales.orders.submit.billingContact}
            />
            <Route
              component={UploadPurchaseOrder}
              path={routes.sales.orders.submit.purchaseOrder}
            />
            <Route
              render={(props) => (
                <SetupAdministrator {...props} godModeUpgrade={initialValues?.godModeUpgrade} />
              )}            
              path={routes.sales.orders.submit.administrator}
            />
            <Route
              component={ConfigurePayment}
              path={routes.sales.orders.submit.payment}
            />
            <Route
              component={ConfirmOrder}
              path={routes.sales.orders.submit.confirmation}
            />
          </OrganizationSelectionProvider>
          <RedirectIfDidntDoPlan />
        </Box>
      </PageContainer>}
    </Form>
  </Formik>;
}

const RedirectIfDidntDoPlan: React.VFC = () => {
  const { pathname } = useLocation();
  const { form } = useSubmitOrderFormHooks({});

  return <>
    {(routes.sales.orders.submit.index.includes(pathname) || (routes.sales.orders.submit.index.includes(pathname) && form.values.organization_name === '')) && <Redirect
      to={routes.sales.orders.submit.plan}
    />}
  </>
}
