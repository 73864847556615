import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, makeStyles, Typography } from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import { useAlert } from "context/AlertProvider";
import endpoints from "endpoints";
import { Form, FormikProvider, useFormik } from "formik";
import useDialogState, { DialogStateProps } from "hooks/useDialogState";
import useCurrentUser from "loaders/useCurrentUser";
import useTeacherInit from "loaders/useTeacherInit";
import { justFetch } from "mutations/mutate";
import PrintDialog from "components/dialogs/PrintDialog";
import React, { useState, useEffect, useMemo } from "react";
import { routes } from "routes";
import { IKlass } from "types/IKlass";
import { ELeadState, IStudentSchoolProfile } from "types/IStudentSchoolProfile";
import * as yup from 'yup';

export const ValentinesSharingDialog: React.FC<DialogStateProps & { klass: IKlass, mazeImgSrc?: string, studentId?: number }> = ({ open, onClose, klass, mazeImgSrc, studentId }) => {
  const sharedClasses = useSharedStyles();
  const alert = useAlert();
  const [secretCode, setSecretCode] = useState<number>();
  const printDialogState = useDialogState();
  const { teacherData } = useTeacherInit();
  const klassStudents = useMemo(() => {
    return teacherData?.students?.filter(({ klass_id }) => klass.id === klass_id) || [];
  }, [teacherData, klass]);
  const focusedStudent = useMemo(() => {
    if (!studentId) {
      return;
    }

    return klassStudents.find(({ id }) => id === studentId);
  }, [klassStudents, studentId])

  useEffect(() => {
    justFetch(endpoints.klass_secret_code(klass.id), 'GET')
      .then(res => {
        if (!res.ok) {
          throw new Error();
        }

        return res.json();
      })
      .then(res => setSecretCode(res))
  }, [])

  return <Dialog open={open} fullWidth>
    <PrintDialog
      open={printDialogState.open}
      onClose={() => {
        printDialogState.onClose();
      }}
      handoutUrl={routes.handouts.valentinesHandouts(klass.id, focusedStudent?.id).slice(1)}
      filename={focusedStudent ? `Kodable Valentine - ${focusedStudent.name}` : `Kodable Valentines - ${klass.klass_name || "New Class"}`}
      v2
    />
    <DialogTitle>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <IconButton
          style={{ visibility: 'hidden' }}
        ><Close /></IconButton>
        {!focusedStudent && <Typography variant="h1">Connect Families to {klass.klass_name}</Typography>}
        {focusedStudent && <Typography variant="h1">Connect {focusedStudent.name}'s family</Typography>}
        <IconButton
          onClick={onClose}
        ><Close /></IconButton>
      </Box>
    </DialogTitle>
    <DialogContent>
      <Box display="flex" flexDirection="column" alignItems="center" className={sharedClasses.vspacing2}>
        <img src={mazeImgSrc || `/images/valentines/no_valentines_placeholder.png`} style={{ maxWidth: 400 }} />
        <Box display="flex" alignItems="center" width="100%" className={sharedClasses.hspacing2}>
          <Box display="flex" flexDirection="column" alignItems="center" flexGrow={1}>
            {!focusedStudent && <Typography variant="subtitle1">Share this link with families:</Typography>}
            {focusedStudent && <Typography variant="subtitle1">Share this link with their family:</Typography>}
            {secretCode ? <TextField
              fullWidth
              value={`${process.env.REACT_APP_KODABLE_WEBSITE}/valentines-connect?code=${secretCode}`}
              onClick={e => {
                (e.target as any).select();
                document.execCommand('copy');
                alert.success('Class Link Copied');
              }}
            /> : <CircularProgress />}
          </Box>
          <Divider flexItem orientation="vertical" />
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            {!focusedStudent && <Typography variant="subtitle1">Print handouts to take home:</Typography>}
            {focusedStudent && <Typography variant="subtitle1">Print a handout to take home:</Typography>}
            <Button
              color="newPink"
              variant="outlined"
              size="large"
              onClick={printDialogState.handleOpen}
              startIcon={<FontAwesomeIcon icon={faPrint} />}
            >Print</Button>
          </Box>
        </Box>
        <Box width="100%">
          {!focusedStudent && <Typography variant="subtitle1" align="center">Invite families by email:</Typography>}
          {focusedStudent && <Typography variant="subtitle1" align="center">Invite their family by email:</Typography>}
          {!focusedStudent && klassStudents.map(student => {
            return <ParentInviteStudentRow student={student} />
          })}
          {focusedStudent && <ParentInviteStudentRow student={focusedStudent} />}
        </Box>
      </Box>
    </DialogContent>
    <DialogActions>
      <Box width="100%" display="flex" justifyContent="center">
        <Button
          variant="outlined"
          onClick={onClose}
        >Close</Button>
      </Box>
    </DialogActions>
  </Dialog>;
}

const useParentInviteStyles = makeStyles(() => ({
  row: {
    '&:nth-child(even)': {
      backgroundColor: 'rgba(195,47,150,0.1)'
    }
  }
}))

const ParentInviteStudentRow: React.FC<{ student: IStudentSchoolProfile }> = ({ student }) => {
  const classes = useParentInviteStyles();
  const sharedClasses = useSharedStyles();
  const { currentUser } = useCurrentUser();
  const [resendInvite, setResendInvite] = useState(false);
  const [inviteSuccess, setInviteSuccess] = useState(false);
  const alert = useAlert();
  const form = useFormik({
    initialValues: {
      parent_email: student?.lead?.email || '',
      student_id: student.id,
      teacher_id: currentUser.id,
      event: 'ValentinesDay2023'
    },
    validationSchema: yup.object({
      parent_email: yup.string().email('Enter a valid email address').required('Enter an email address')
    }),
    onSubmit: values => {
      return justFetch(endpoints.parentLeads, 'POST', values)
        .then(res => {
          if (res.status === 201) {
            setInviteSuccess(true);
            setResendInvite(false);
          } else {
            throw new Error();
          }
        })
        .catch(e => {
          console.log(e);
          alert.error('An error occurred inviting this student\'s family.')
        })
    }
  })

  return <Box display="flex" flexDirection="row" alignItems="center" className={classes.row} p={1} height={56}>
    <Box width={200}>
      <Typography>{student.name}</Typography>
    </Box>
    {student.lead?.state === ELeadState.linked && <Box display="flex" flexDirection="row" alignItems="center" flexGrow={1}>
      <Check />&nbsp;<Typography>Connected!</Typography>
    </Box>}

    {(student.lead?.state === ELeadState.invited || inviteSuccess) && !resendInvite && <Box display="flex" alignItems="center" justifyContent="space-between" flexGrow={1}>
      <Box display="flex" alignItems="center">
      <Typography>Invited</Typography>
      </Box>
      <Button
        color="newPink"
        onClick={() => { setResendInvite(true); setInviteSuccess(false); }}
        variant="text"
      >Resend Invite</Button>
    </Box>}

    {(student.lead?.state === ELeadState.not_invited || !student.lead || student.lead?.state === null || resendInvite) && !inviteSuccess && <FormikProvider value={form}><Form style={{ flexGrow: 1 }}>
      <Box display="flex" alignItems="center" className={sharedClasses.hspacing2}>
        <TextField
          placeholder="Email Address"
          error={!form.isValid && form.submitCount > 0}
          value={form.values.parent_email}
          name="parent_email"
          onChange={form.handleChange}
          style={{ flexGrow: 1 }}
          fullWidth
        />
        {!form.isSubmitting && <Button
          variant="contained"
          color="newPink"
          disabled={!form.isValid}
          type="submit"
          style={{ height: 40 }}
        >Send</Button>}
        {form.isSubmitting && <CircularProgress />}
      </Box>
    </Form></FormikProvider>}
  </Box>;
}