import Button from 'components/ui/buttons/Button'
import useDialogState from 'hooks/useDialogState'
import { SubscriptionAssignLicenseDialog } from './SubscriptionAssignLicenseDialog'
import React from "react";

export const SubscriptionAssignLicenseButton: React.FC<{subscriptionId: number}> = ({subscriptionId}) => {
    const dialogState = useDialogState()

    return (
        <>
            <SubscriptionAssignLicenseDialog subscriptionId={subscriptionId} {...dialogState} />
            <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => dialogState?.handleOpen()}
            >
                + Assign License
            </Button>
        </>
    )
}