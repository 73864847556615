import React from "react";
import {Box, makeStyles, Paper, Typography, useTheme} from "@material-ui/core";
import useSharedStyles from "../../useSharedStyles";
import {GameTitle} from "../../../enums/GameTitle";
import GradeChip, {GradeChipProps} from "../../ui/GradeChip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUniversity, faUser} from "@fortawesome/free-solid-svg-icons";
import Button from "../../ui/buttons/Button";
import AssignedGameBadge from "./AssignedGameBadge";
import CleverIcon from "./CleverIcon";

const useViewClassesListStyles = makeStyles(theme => ({
  classCard: {
    transition: 'transform 0.25s',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.01)'
    }
  }
}))

export interface KlassCardProps {
  clever: boolean,
  klassCode: string,
  klassName: string,
  assignedGames: GameTitle[],
  studentNames: string[] | undefined,
  teacherName: string | undefined,
  schoolName?: string,
  grade: GradeChipProps["grade"],
  onClick: () => void
}

export const KlassCard: React.VFC<KlassCardProps> =
  ({
    clever,
    klassCode,
    klassName,
    assignedGames,
    studentNames,
    teacherName,
    schoolName,
    grade,
    onClick
  }) => {
  const classes = useViewClassesListStyles();
  const theme = useTheme();
  const sharedClasses = useSharedStyles();

  return <Box display="flex" flexDirection="row" justifyContent="space-between"
              component={Paper} {...{variant: 'outlined'}} p={2} width="100%" className={classes.classCard}
              onClick={onClick}>
    <Box display="flex" justifyContent="space-between" minWidth={350}>
      <Box display="flex" flexDirection="column" maxWidth={200}>
        <Box display="flex" className={sharedClasses.hspacing1}>
          {clever &&
            <CleverIcon/>
          }
          <Typography style={{fontWeight: 'bold', textDecoration: 'underline'}}>{klassName}</Typography>
        </Box>
        <Box>
          <Typography style={{fontWeight: 'bold', fontSize: '0.75rem'}}
                      display="inline">{klassCode.toUpperCase()} </Typography>
          <Typography style={{fontSize: '0.75rem', opacity: 0.75}} display="inline">Class Code</Typography>
        </Box>
      </Box>
      <Box display="flex" width='115px' justifyContent={'center'} alignItems={'center'}>
        <GradeChip kVariant="full" grade={grade}/>
      </Box>
    </Box>
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" minWidth='350px'>
        <FontAwesomeIcon icon={faUser} color={theme.palette.teal.main}/>&nbsp;&nbsp;<Typography>
        {(!studentNames || studentNames?.length === 0) && 'None'}
        {studentNames?.slice(0, 3).join(', ')}
        {studentNames && studentNames.length > 3 && <>&nbsp;and {studentNames?.length - 3} more</>}
      </Typography>
      </Box>
      <Box mt={1} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
        <FontAwesomeIcon icon={faUniversity} color={theme.palette.purple.main}/>&nbsp;&nbsp;
        <Typography>
          {teacherName && teacherName}{schoolName && `, ${schoolName}`}
        </Typography>
      </Box>
    </Box>
    <AssignedGameBadge
      basics={assignedGames?.includes(GameTitle.BASICS)}
      creator={assignedGames?.includes(GameTitle.CREATOR)}
    />
    <Box display="flex" alignItems="center">
      <Button
        color="green"
        variant="contained"
      >
        View Class
      </Button>
    </Box>
  </Box>
}