import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import { useAlert } from "context/AlertProvider";
import { useOrganizationProfile } from "context/OrganizationProfileProvider";
import endpoints from "endpoints";
import { useFormik } from "formik";
import { DialogStateProps } from "hooks/useDialogState";
import { justFetch } from "mutations/mutate";
import React, { useEffect, useState } from "react";
import { ISubscription } from "types/ISubscription";
import * as yup from 'yup';

export const SubscriptionAssignLicenseDialog: React.FC<DialogStateProps & {subscriptionId: number}> = ({ open, onClose, subscriptionId }) => {
  const [error, setError] = useState<string>();
  const alert = useAlert();
  const sharedClasses = useSharedStyles();
  const { organization_profile, mutate } = useOrganizationProfile()
  const [subscription, setSubscription] = useState<ISubscription>()

  useEffect(() => {
    if (!organization_profile || !organization_profile?.subscriptions) {
      return
    }

    const sub = organization_profile?.subscriptions?.find((sub) => sub.id === subscriptionId)
    if (!sub) {
      return
    }
    setSubscription(sub)

    return () => {
        setSubscription(undefined)
    }
  }, [organization_profile, subscriptionId])

  const form = useFormik({
    initialValues: {
        assignee_email: ''
    },
    validationSchema: yup.object({
        assignee_email: yup.string().email().required(),
    }),
    onSubmit: values => {
        if (!subscriptionId || !subscription) {
          return
        }

        if (subscription?.licenses?.filter((license) => license?.activated)?.length >= subscription?.license_count) {
            alert.error('This subscription has already reached the maximum number of activated licenses')
            return
        }

        const data = {
            assignee_email: values.assignee_email
        }

        return justFetch(endpoints.godmode.assignLicense(subscriptionId), 'POST', data)
            .then(res => {
                if (res.ok) {
                    alert.success('License assigned successfully!')
                    form.resetForm()
                    onClose()
                    mutate()
                } else {
                    throw new Error("Could not assign license")
                }
            })
            .catch(() => setError('Unknown error'))
        }
  })

  if (!organization_profile) {
    return null
  }

  return <Dialog open={open}>
    <DialogTitle>Assign a License for Subscription {subscriptionId}</DialogTitle>
    <DialogContent>
      <Box mb={1} className={sharedClasses?.vspacing2}>
        <TextField
          label={<>New license assignee email<sup style={{color: 'red'}}>*</sup></>}
          name="assignee_email"
          value={form.values.assignee_email}
          onChange={form.handleChange}
          helperText={form.errors.assignee_email}
          error={!!form.errors.assignee_email}
        />
      </Box>

      {error && <Alert severity="error">{error}</Alert>}
    </DialogContent>
    <DialogActions>
      <Button
        variant="outlined"
        onClick={() => {
          form.resetForm()
          onClose()
          setError(undefined)
        }}
        disabled={form.isSubmitting}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={form.isSubmitting || !form.isValid}
        onClick={form.submitForm}
      >
        Assign
      </Button>
    </DialogActions>
  </Dialog>;
}