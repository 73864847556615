import { Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, LinearProgress, Radio, RadioGroup, Typography } from "@material-ui/core"
import MenuItem from 'components/ui/MenuItem';
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import { useFormik } from "formik"
import useTeacherInit from "loaders/useTeacherInit"
import React, { useEffect, useMemo } from "react";
import { gradesArray } from "types/Grades";
import * as Yup from 'yup';
import Select from "components/ui/Select";
import { Alert } from "@material-ui/lab";
import { justFetch } from "mutations/mutate";
import endpoints from "endpoints";
import { useState } from "react";
import { mutate } from "swr";
import { IClassDialogProps } from "../../../pages/classes/IClassDialogProps";
import { IKlass } from "types/IKlass";
import { useAlert } from "context/AlertProvider";

type FormData = IKlass & { password_option: 'none' | 'code' | 'picture' };

const ClassSettingsDialog: React.VFC<IClassDialogProps> = ({ classId, open, onClose = () => { } }) => {
  const { teacherData } = useTeacherInit();
  const classData = useMemo(() => {
    return teacherData?.klasses.find(({ id }) => id === classId)!;
  }, [teacherData, classId]);

  const [submitError, setSubmitError] = useState<string | false>(false);
  const alert = useAlert();

  const form = useFormik<FormData>({
    enableReinitialize: true,
    initialValues: classData ? {
      ...classData,
      password_option: (classData?.password_enabled ? 'picture' : (classData?.code_password_enabled ? 'code' : 'none'))
    } : {} as FormData,
    onSubmit: values => {
      return justFetch(endpoints.classById(classData.id), 'PUT', {
        klass_name: values.klass_name,
        grade: values.grade,
        klass_code: values.klass_code,
        password_enabled: values.password_option === 'picture',
        code_password_enabled: values.password_option === 'code'
      })
        .then(res => {
          if (!res.ok) {

            if (res.status === 422) {
              form.setFieldError('klass_code', 'This class code is already being used by another class')
            } else {
              res.json().then(body => setSubmitError(body.message || body.error || 'An unknown error occurred.'))
            }

          } else {
            mutate(endpoints.teacherInit);
            alert.success('Class Settings Saved');
            onClose();
          }
        })
        .catch(() => {
          setSubmitError('An unknown error occurred.');
        })
    },
    validationSchema: Yup.object({
      klass_name: Yup.string().required('Please enter a name for your class'),
      klass_code: Yup.string().required('A class code is required').min(5, 'Your class code must be at least 5 characters')
    })
  });

  useEffect(() => {
    if (open) {
      form.handleReset(null);
      setSubmitError(false);
    }
  // eslint-disable-next-line
  }, [open]);

  const sharedClasses = useSharedStyles();

  return (
    <Dialog
      open={open}
      fullWidth
    >
      <LinearProgress style={{ visibility: form.isSubmitting ? 'visible' : 'hidden' }}></LinearProgress>
      <form onSubmit={form.handleSubmit}>
        <DialogTitle>Edit Class Settings</DialogTitle>
        <DialogContent className={sharedClasses.vspacing2}>
          <TextField
            label="Class Name"
            id="klass_name"
            value={form.values.klass_name}
            onChange={form.handleChange}
            helperText={form.errors.klass_name}
            error={!!form.errors.klass_name}
          />
          <Select
            label="Class Grade"
            id="grade"
            onChange={e => form.setFieldValue('grade', e.target.value)}
            value={form.values.grade}
          >
            {gradesArray
              .map(grade => <MenuItem
                key={grade.key}
                value={grade.key}
              >
                {grade.name}
              </MenuItem>)
            }
          </Select>

          <TextField
            label="Class Code"
            id="klass_code"
            value={form.values.klass_code}
            onChange={form.handleChange}
            helperText={form.errors.klass_code}
            error={!!form.errors.klass_code}
          />

          <FormControl component="fieldset">
            <Box mb={1}>
              <label htmlFor="password_option">
                <Typography
                  variant="subtitle1"
                >Passwords</Typography>
              </label>
            </Box>
            <RadioGroup aria-label="password_option" id="password_option" name="password_option" value={form.values.password_option} onChange={form.handleChange}>
              <FormControlLabel value="none" control={<Radio />} label={
                <Box mb={1}>
                  <Typography variant="body1">None</Typography>
                  <Typography variant="body2">Students log in without a password</Typography>
                </Box>
              } />
              <FormControlLabel value="picture" control={<Radio />} label={
                <Box mb={1}>
                  <Typography variant="body1">Picture</Typography>
                  <Typography variant="body2">Students select a unique picture to log in</Typography>
                </Box>
              } />
              <FormControlLabel value="code" control={<Radio />} label={
                <Box mb={1}>
                  <Typography variant="body1">Parent Code</Typography>
                  <Typography variant="body2">Students log in with their unique parent code</Typography>
                </Box>
              } />
            </RadioGroup>
          </FormControl>

          {
            !form.isValid && form.submitCount > 0 && <Box><Alert severity="error">Please correct the errors above to continue.</Alert></Box>
          }

          {submitError && <Alert severity="error" action={
            <Button
              color="inherit"
              size="small"
              onClick={() => form.submitForm()}
            >Try again</Button>
          } >{submitError}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            type="reset"
            disabled={form.isSubmitting}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={form.isSubmitting}
            disableElevation
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ClassSettingsDialog;