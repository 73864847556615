import React from "react";
import {GameTitle} from "../../../enums/GameTitle";
import {IKlass} from "../../../types/IKlass";
import useSharedStyles from "../../../components/useSharedStyles";
import useDialogState from "../../../hooks/useDialogState";
import {Box, Link, Paper, Typography} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faQrcode} from "@fortawesome/free-solid-svg-icons";
import QRCodeDialog from "../../../components/dialogs/QRCodeDialog";
import {StudentInstructionsDialog} from "../../../components/dialogs/StudentInstructionsDialog";
import Button from "../../../components/ui/buttons/Button";
import {InfoCard} from "./ViewClassDashboard";

export const NonCleverInstructions: React.VFC<{ game: GameTitle, klass: IKlass }> = ({game, klass}) => {
  const sharedClasses = useSharedStyles();
  const qrCodeDialogState = useDialogState();
  const studentInstructionsDialogState = useDialogState();

  const qrStep = <>
    Enter class code&nbsp;<Box display="inline" border="2px solid rgba(0,0,0,0.5)" borderRadius={6} p={0.5}
                               color="#27a9e1" style={{
    fontFamily: 'monospace',
    textTransform: 'uppercase'
  }}>{klass?.klass_code}</Box>&nbsp;and click Add Class or&nbsp;<Link className={sharedClasses.hoverCursorPointer}
                                                                      onClick={qrCodeDialogState.handleOpen}><FontAwesomeIcon
    icon={faQrcode}/> Show QR Code</Link>
  </>

  return <Box className={sharedClasses.vspacing4}>
    <QRCodeDialog {...qrCodeDialogState} classCode={klass?.klass_code}/>
    <StudentInstructionsDialog
      klass={klass}
      {...studentInstructionsDialogState}
    />
    <Box component={Paper} {...{variant: 'outlined'}} p={4} display="flex" flexDirection="column"
         alignItems="flex-start">
      <img
        style={{
          maxWidth: '300px',
          marginBottom: '2rem'
        }}
        src={`images/logos/kodable-${game}-horizontal.svg`}
        alt={`Kodable ${game} logo`}
      />
      <InfoCard
        title="Computers"
        icon="/images/class/share-with-parents/k-computer.svg"
        teaser={
          <>
            <Typography>Go to <Link target="_blank"
                                    href={`${process.env.REACT_APP_GAME_ENDPOINT}/class/${klass?.klass_code}`}>{process.env.REACT_APP_GAME_ENDPOINT}/class/{klass?.klass_code}</Link></Typography>
          </>
        }
        content={
          <ol className={sharedClasses.vspacing2} style={{listStyle: 'decimal', paddingLeft: 16}}>
            <li><Typography>Click on profile name.</Typography></li>
            <li><Typography>Choose "I'm at School".</Typography></li>
          </ol>
        }
      />
      {game !== GameTitle.CREATOR &&
        <>
          <div style={{marginBottom: '3rem'}}></div>
          <InfoCard
            title="iPads"
            icon="/images/class/share-with-parents/k-ipad.svg"
            teaser={
              <>
                <Typography>
                  {qrStep}
                </Typography>
              </>
            }
            content={
              <ol className={sharedClasses.vspacing2} style={{listStyle: 'decimal', paddingLeft: 16}}>
                <li><Typography>Open the Kodable iOS app.</Typography></li>
                <li><Typography>Choose "Sign In".</Typography></li>
                <li><Typography>Choose "Use a Class Code".</Typography></li>
                <li><Typography>
                  Enter class code <Typography style={{fontFamily: 'monospace', textTransform: 'uppercase'}}
                                               display="inline">{klass?.klass_code}</Typography>
                </Typography></li>
                <li><Typography>Click on profile name.</Typography></li>
              </ol>
            }
          />
        </>
      }
      <Button
        startIcon={<FontAwesomeIcon icon={faDownload}/>}
        variant="contained"
        color="orange"
        size="large"
        style={{marginLeft: 'auto'}}
        onClick={studentInstructionsDialogState.handleOpen}
      >Student Instructions</Button>
    </Box>
  </Box>;
}