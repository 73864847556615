import {
  Box,
  Divider,
  Drawer as MatDrawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  makeStyles,
  Toolbar,
  Typography
} from '@material-ui/core';
import useNavStyles from './useNavStyles';
import { ClassesProp } from 'components/ui/ClassesProp';
import { faArrowLeft, faBook, faCashRegister, faGamepad, faLink, faMapMarkedAlt, faMoneyBill, faPlus, faPlusSquare, faStar, faTimes, faUser, faUsers, faCalendarDay, faBrain, faChildren, faChalkboardTeacher, faHome, faSchool, faFile, faDollar, faCoins, faGift } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, Link as RouterLink, Link } from 'react-router-dom';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import endpoints from 'endpoints';
import useCurrentUser from 'loaders/useCurrentUser';
import useSWR from 'swr';
import { IStudentSchoolProfile } from 'types/IStudentSchoolProfile';
import React, { useState } from 'react';
import NewStudentModal from 'components/modal/NewStudentModal';
import Promo from './Promo';
import { useContext } from 'react';
import { NavContext } from 'context/NavProvider';
import { ITeacherData } from 'types/ITeacherData';
import classNames from 'classnames';
import useSharedStyles from 'components/useSharedStyles';
import useDialogState from 'hooks/useDialogState';
import ParentInvites, { TrackParentInviteMethod, TrackParentInviteMethodLocation } from 'utils/ParentInvites';
import { EventsContext, IEventResponse, IEventConfigParsed } from 'context/EventsProvider';
import {routes} from "../../routes";
import useSubscription from "../../loaders/useSubscription";
import { motion } from "framer-motion";
import {subscriptionExpiresSoon} from "../../pages/godmode/utils/time";
import Tooltip from "../ui/Tooltip";
import PaywallManageLearningDialog from "../dialogs/paywalls/PaywallManageLearningDialog";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons/faCircleExclamation";
import CleverIcon from "../cards/KlassCard/CleverIcon";

type NewStudentModalState = 'INITIAL' | 'ADD_NEW_PLAYER' | 'USE_PARENT_CODE'

const Drawer: React.VFC<ClassesProp<typeof useNavStyles>> = (props) => {
  const { currentUser } = useCurrentUser();
  const classes = useNavStyles(props);
  const { drawerState: [drawerOpen, setDrawerOpen] } = useContext(NavContext);
  const [openNewStudentModal, setOpenNewStudentModal] = useState(false);
  const [defaultModalState, setDefaultModalState] = useState<NewStudentModalState>('INITIAL')

  if (!currentUser) {
    return null;
  }

  const drawerContent = <>
    <Hidden smDown>
      <Box>
        <Toolbar />
      </Box>
    </Hidden>
    <Hidden mdUp>
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          color="inherit"
          onClick={() => setDrawerOpen(false)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
      </Box>
    </Hidden>
    <div className={classes.drawerContainer} style={{ height: '100%' }}>
      {currentUser?.is_parent && !window.location.pathname.includes('godmode') && 
      <ParentLinks
        onOpenNewStudentModal={(settings) => {
            if (!!settings && !!settings?.default) {
              setDefaultModalState(settings?.default)
            }
            setOpenNewStudentModal(true)
          }
        }
      />
      }
      {currentUser?.is_parent === false && !window.location.pathname.includes('godmode') && <TeacherLinks />}
      {window.location.pathname.includes('godmode') && <GodmodeLinks />}
    </div>
  </>;

  return <>
    {openNewStudentModal && (
      <NewStudentModal
        defaultStep={defaultModalState}
        closeModal={() => {
          setDefaultModalState('INITIAL')
          setOpenNewStudentModal(false)
        }}
      />
    )}
    <Hidden mdUp>
      <MatDrawer
        className={classes.drawer}
        variant="temporary"
        open={drawerOpen}
        classes={{
          root: currentUser?.is_parent ? classes.drawerRootParent : classes.drawerRootTeacher,
          paper: currentUser?.is_parent ? classes.drawerPaperParent : classes.drawerPaperTeacher,
        }}
      >
        {drawerContent}
      </MatDrawer>
    </Hidden>
    <Hidden smDown>
      <MatDrawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          root: currentUser?.is_parent ? classes.drawerRootParent : classes.drawerRootTeacher,
          paper: currentUser?.is_parent ? classes.drawerPaperParent : classes.drawerPaperTeacher,
        }}
      >
        {drawerContent}
      </MatDrawer>
    </Hidden>
  </>;
}

const ParentLinks: React.VFC<{ onOpenNewStudentModal: (...args: any[]) => any }> = ({ onOpenNewStudentModal }) => {
  const { currentUser } = useCurrentUser();
  const eventsContextState = useContext(EventsContext)
  const { data: { students } = { students: [] } } = useSWR<{ students: IStudentSchoolProfile[] }>(currentUser?.is_parent ? endpoints.parent_init : null);
  const [showPromo, setShowPromo] = useState(currentUser?.plan === 'Free');
  const currentEvent : IEventResponse | null = eventsContextState?.events?.length > 0 ? eventsContextState?.events[0] : null
  const currentEventConfig : IEventConfigParsed | null = currentEvent !== null && currentEvent?.event_config !== null ? JSON.parse(currentEvent?.event_config) : null

  return <>
    <List>
      <DrawerItem
        text="Learning Tools"
        icon={<FontAwesomeIcon className="text-kodable-purple" icon={faBook} size="lg" />}
        to={routes.learningTools}
      />
      <DrawerItem
        text="Videos"
        icon={<FontAwesomeIcon className="text-kodable-red" icon={faYoutube} size="lg" />}
        to={routes.parentVideos}
      />
    </List>
    {students.length > 0 && <>
      <Divider />
      <List>
        {
          students.map(student => <DrawerItem
            key={student.id}
            text={student.name}
            icon={<FontAwesomeIcon className="text-kodable-teal" icon={faUser} size="lg" />}
            to={routes.students.viewStudent(student.id)}
          />)
        }
      </List>
      <Divider />
    </>}
    <List>
      {
        students.length < 4 && !currentUser?.temp_password && (
          <DrawerItem
            text="Add Student"
            icon={<FontAwesomeIcon className="text-kodable-blue" icon={faPlus} size="lg" />}
            onClick={() => onOpenNewStudentModal()}
          />
        )
      }
      <DrawerItem
        text="Use a Parent Code"
        icon={<FontAwesomeIcon className="text-kodable-blue" icon={faLink} size="lg" />}
        onClick={() => onOpenNewStudentModal({'default': 'USE_PARENT_CODE'})}
      />
      {currentEventConfig !== null && currentEventConfig?.sidebar_banner_link !== null && currentEventConfig?.sidebar_banner_img !== null &&
        <Box display="flex" justifyContent="center" mt={2}>
          <a
            target="_blank"
            rel="noreferrer"
            style={{
              marginTop: '-6px',
              display: 'block'
            }}
            href={currentEventConfig?.sidebar_banner_link}
          >
            <img
              alt=""
              style={{
                margin: 'auto',
                borderRadius: '3px',
                overflow: 'hidden',
                display: 'block',
                maxWidth: '183px'
              }}
              src={currentEventConfig?.sidebar_banner_img}
            />
          </a>
        </Box>
      }
    </List>
    {showPromo && <Promo setShowPromo={setShowPromo} />}
  </>
}

const useTeacherLinkStyles = makeStyles((theme) => ({
  "@keyframes pulsate": {
    "0%": {
      transform: "scale(1)"
    },
    "50%": {
      transform: "scale(1.15)"
    },
    "100%": {
      transform: "scale(1)"
    }
  },
  pulsating: {
    animation: "$pulsate 1200ms infinite ease-in-out"
  }
}))

const TeacherLinks = () => {
  const { currentUser } = useCurrentUser();
  const location = useLocation();
  const { data: teacherData } = useSWR<ITeacherData>(endpoints.teacherInit);
  const paywallDialogState = useDialogState();
  const teacherLinkStyles = useTeacherLinkStyles()
  const eventsContextState = useContext(EventsContext)
  const currentEvent : IEventResponse | null = eventsContextState?.events?.length > 0 ? eventsContextState?.events[0] : null
  const currentEventConfig : IEventConfigParsed | null = currentEvent !== null && currentEvent?.event_config !== null ? JSON.parse(currentEvent?.event_config) : null
  const {subscription} = useSubscription();

  if (!currentUser) {
    return null;
  }

  const invitedKlasses = teacherData?.klasses?.filter((klass) => {
    if (!klass?.parent_invites) {
      return false
    }
    // If any of the methods, email, download, or link have a value of 1, then the teacher
    // is considered as having invited this klass parents
    return Object.values(klass?.parent_invites).indexOf(1) > -1
  })

  const hasInvitedAllKlasses = invitedKlasses?.length === teacherData?.klasses?.length

  return <List style={{paddingTop: '0'}}>
    <PaywallManageLearningDialog open={paywallDialogState.open} onClose={paywallDialogState.onClose} />
    <DrawerItem
      icon={
        <img
          alt=""
          className={!hasInvitedAllKlasses ? teacherLinkStyles.pulsating : ''}
          style={{height: hasInvitedAllKlasses ? '22px' : '44px', width: 'auto'}}
          src={hasInvitedAllKlasses ? '/images/onboarding/heart-key.png' : '/images/class/share-with-parents/exclamation.png'}
        />
      }
      text="Invite Families"
      to={routes.parentConnect}
      onClick={() => {
        ParentInvites.track(null, TrackParentInviteMethod.clicked, TrackParentInviteMethodLocation.sidebar)
      }}
      selected={(location.pathname.indexOf(routes.parentConnect) > -1)}
      emphasize={true}
    />
    <DrawerItem
      icon={<FontAwesomeIcon className={location.pathname.startsWith('/classes') ? 'text-white' : "text-kodable-blue"} icon={(teacherData?.klasses.length || 0) > 0 ? faUsers : faPlusSquare} size="lg" />}
      text={(teacherData?.klasses.length || 0) > 0 ? 'My Classes' : 'Add Class'}
      to={routes.classes.index}
      selected={location.pathname.startsWith('/classes')}
    />
    {(teacherData?.klasses.length || 0) > 0 && <DrawerItem
      icon={<FontAwesomeIcon className={location.pathname.startsWith('/students') ? 'text-white' : "text-kodable-teal"} icon={faUser} size="lg" />}
      text="My Students"
      to={routes.students.index}
      selected={location.pathname.startsWith('/students')}
    />}
    <DrawerItem
      icon={<FontAwesomeIcon className={location.pathname.startsWith(routes.courses.index) ? 'text-white' : "text-kodable-pink"} icon={faMapMarkedAlt} size="lg" />}
      text="Courses"
      to={routes.courses.index}
      selected={location.pathname.startsWith(routes.courses.index)}
    />
    <DrawerItem
      icon={<FontAwesomeIcon className={location.pathname.startsWith(routes.demo.index) ? 'text-white' : "text-kodable-green"} icon={faGamepad} size="lg" />}
      text="Student View"
      to={routes.demo.index}
      selected={location.pathname.startsWith(routes.demo.index)}
    />
    <DrawerItem
      iconUrl={'/images/badges/premium-badge-100.png'}
      text="Reports"
      accessExpiring={subscriptionExpiresSoon(subscription.end_date, 30)}
      to={currentUser.plan === 'School' ? routes.reports.index : undefined}
      onClick={() => {
        if (currentUser.plan === 'Free') {
          paywallDialogState.handleOpen();
        }
      }}
      selected={location.pathname.startsWith(routes.reports.index)}
    />
    {(currentUser.plan === 'Free' || (currentUser.plan === 'School' && currentUser.is_administrator)) && <DrawerItem
      iconUrl={'/images/badges/premium-badge-100.png'}
      text="Add Co-Teachers"
      accessExpiring={subscriptionExpiresSoon(subscription.end_date, 30)}
      to={currentUser.plan === 'School' ? routes.admin.index : undefined}
      onClick={() => {
        if (currentUser.plan === 'Free') {
          paywallDialogState.handleOpen();
        }
      }}
      selected={location.pathname.startsWith('/admin')}
    />}
    <Divider />
    <DrawerItem
      text="Videos"
      icon={<FontAwesomeIcon className="text-kodable-red" icon={faYoutube} size="lg" />}
      to={routes.videos}
    />
    {currentEventConfig !== null && currentEventConfig?.sidebar_banner_link !== null && currentEventConfig?.sidebar_banner_img !== null &&
      <Box display="flex" justifyContent="center" mt={2}>
        <a
          target="_blank"
          rel="noreferrer"
          style={{
            marginTop: '-6px',
            display: 'block'
          }}
          href={currentEventConfig?.sidebar_banner_link}
        >
          <img
            alt=""
            style={{
              margin: 'auto',
              borderRadius: '3px',
              overflow: 'hidden',
              display: 'block',
              maxWidth: '183px'
            }}
            src={currentEventConfig?.sidebar_banner_img}
          />
        </a>
      </Box>
    }
  </List>
}

interface DrawerItemProps {
  icon?: React.ReactNode;
  iconUrl?: string;
  text: string;
  href?: string;
  to?: string;
  onClick?: React.EventHandler<React.MouseEvent>;
  selected?: boolean;
  emphasize?: boolean;
  target?: '_blank' | '_self' | '_parent' | '_top'
  exact?: boolean;
  accessExpiring?: boolean;
}

const useDrawerItemStyles = makeStyles(() => ({
  selected: {
    color: 'white !important',
    '&:hover': {
      color: 'white !important'
    }
  },
  button: {
    '&:hover': {
      color: '#27a9e1 !important'
    },
  },
  hoverExpand: {
    transition: 'transform 0.25s',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.01)'
    }
  }
}))



const DrawerItem: React.VFC<DrawerItemProps> = ({ accessExpiring, icon, iconUrl, text, to, href, onClick = () => { }, selected: _selected, emphasize, target = '_blank' , exact}) => {
  const location = useLocation();
  const selected = _selected !== undefined ? _selected : (!!to && location.pathname.includes(to) && (exact ? location.pathname === to : true));
  const { drawerState: [drawerOpen, setDrawerOpen] } = useContext(NavContext);
  const classes = useDrawerItemStyles();
  const sharedClasses = useSharedStyles();

  const IconComponent = () => {
    return accessExpiring ? (
      <Tooltip title={'Access to this feature is expiring soon!'} arrow placement={'bottom-end'}>
        <ListItemIcon style={{ justifyContent: 'center' }}>
          <motion.div animate={{scale: [1.2, 1.4, 1.2]}}
                      transition={{
                        scale: {
                          duration: 1.5,
                          repeat: Infinity,
                          repeatType: 'loop',
                          ease: 'easeInOut'
                        }
                      }}>
            <FontAwesomeIcon icon={faCircleExclamation} color={'red'} />
          </motion.div>
        </ListItemIcon>
      </Tooltip>
    ) : (
      <ListItemIcon style={{justifyContent: 'center'}}>
        <img width={20} src={iconUrl} />
      </ListItemIcon>
    )
  }

  return (
    <ListItem
      button
      selected={selected}
      onClick={(e: React.MouseEvent) => {
        setDrawerOpen(false);
        onClick(e);
      }}
      classes={{
        selected: classNames(sharedClasses.highlightBgColor, classes.selected),
        button: !selected ? classes.button : ''
      }}
      {...{
        component: to ? RouterLink : 'a',
        to,
        href,
        disableRipple: true,
        disableTouchRipple: true,
        target: href ? target : undefined
      }}
      style={{
        paddingLeft: 0
      }}
    >
      {icon && <ListItemIcon style={{ justifyContent: 'center' }}>{icon}</ListItemIcon> }
      {iconUrl && <IconComponent /> }
      <Typography style={{ fontSize: '0.85rem', fontWeight: emphasize ? 'bold' : selected ? 600 : undefined, padding: emphasize ? '.75rem 0' : '0' }}>{text}</Typography>
    </ListItem>
  );
}

export const GodmodeLinks: React.FC = () => {
  const today = new Date();
    
  const isJonsBirthday = today.getMonth() === 9 && today.getDate() === 20;

  return <List style={{ height: '100%' }}>
    <DrawerItem
      text="Home"
      icon={<FontAwesomeIcon className="text-kodable-red" icon={faHome} size="lg" />}
      to={routes.godmode.index}
      exact={true}
    />
    <Box m={1}>
      <Typography variant="h6">Users</Typography>
    </Box>
    <DrawerItem
      text="Teachers"
      icon={<FontAwesomeIcon className="text-kodable-red" icon={faChalkboardTeacher} size="lg" />}
      to={routes.godmode.userLookup}
    />
    <DrawerItem
      text="Students"
      icon={<FontAwesomeIcon className="text-kodable-red" icon={faChildren} size="lg" />}
      to={routes.godmode.students.index}
    />
    <DrawerItem
      text="Ambassadors"
      icon={<FontAwesomeIcon className="text-kodable-red" icon={faStar} size="lg" />}
      to={routes.godmode.ambassadors}
    />
    <Box m={1}>
      <Typography variant="h6">Sales</Typography>
    </Box>
    <DrawerItem
      text="Dashboard"
      icon={<FontAwesomeIcon className="text-kodable-red" icon={faCashRegister} size="lg" />}
      to={routes.godmode.sales.index}
      exact={true}
    />
    <DrawerItem
      text="Revenue"
      icon={<FontAwesomeIcon className="text-kodable-red" icon={faDollar} size="lg" />}
      to={routes.godmode.revenue.index}
    />
    <DrawerItem
      text="Quotes"
      icon={<FontAwesomeIcon className="text-kodable-red" icon={faCoins} size="lg" />}
      to={routes.godmode.sales.quotes}
      exact={true}
    />
    <DrawerItem
      text="Organizations"
      icon={<FontAwesomeIcon className="text-kodable-red" icon={faMoneyBill} size="lg" />}
      to={routes.godmode.organizationProfiles.index}
      exact={true}
    />
    <Box m={1}>
      <Typography variant="h6">Events</Typography>
    </Box>
    <DrawerItem
      text="Event list"
      icon={<FontAwesomeIcon className="text-kodable-red" icon={faCalendarDay} size="lg" />}
      to={routes.godmode.events.index}
    />
    <Box m={1}>
      <Typography variant="h6">Organizations</Typography>
    </Box>
    <DrawerItem
      text="Clever Districts"
      icon={<FontAwesomeIcon className="text-kodable-red" icon={faBrain} size="lg" />}
      to={routes.godmode.cleverDistricts}
    />
    <DrawerItem
      text="Schools"
      icon={<FontAwesomeIcon className="text-kodable-red" icon={faSchool} size="lg" />}
      to={routes.godmode.schools.index}
    />
    <Box m={1}>
      <Typography variant="h6">Administration</Typography>
    </Box>
    <DrawerItem
      text="Resources"
      icon={<FontAwesomeIcon className="text-kodable-red" icon={faFile} size="lg" />}
      to={routes.godmode.resources.index}
    />
    <DrawerItem
      text="Gift Codes"
      icon={<FontAwesomeIcon className="text-kodable-red" icon={faGift} size="lg" />}
      to={routes.godmode.giftCodes}
    />
    <DrawerItem
      text="Leave Godmode"
      icon={<FontAwesomeIcon icon={faArrowLeft} size="lg" />}
      to={routes.classes.index}
    />
    <Box flexGrow={1} position="absolute" bottom={32}>
      <Link
        to={routes.classes.index}
        style={{
          textDecoration: 'none',
          color: 'inherit',
          display: 'flex'
        }}
      >
        <Box position="relative" style={{top: '23px'}}>
          {isJonsBirthday && 
            <img
              src="/images/godmode/birthday-hat.png"
              alt="Happy Birthday Jon!"
              style={{
                position: 'absolute',
                width: '40px',
                top: '-22px',
                left: '6px'
              }}
            />
          }
          <img
            width="50"
            height="50"
            src="/images/godmode/skepticaljon.png"
            alt="Skeptical Jon"
            style={{
              marginRight: '.5rem'
            }}
          />
        </Box>
      </Link>
    </Box>
  </List>;
}

export default Drawer;