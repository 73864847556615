import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Link, Typography } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import endpoints from "endpoints";
import useDialogState from "hooks/useDialogState";
import useTeacherInit from "loaders/useTeacherInit";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "routes";
import useSWR from "swr";
import { IKlass } from "types/IKlass";
import { ELeadState } from "types/IStudentSchoolProfile";
import { ConnectWithFamiliesDialog } from "components/dialogs/teacher/ConnectWithFamiliesDialog";
import { ValentinesSharingDialog } from "./ValentinesSharingDialog";

const purp = '#C32F96'

export interface ValentinesStudentMazeData {
  image_url: string;
  message: string;
}

export const TeacherValentineClassList: React.VFC = () => {
  const { teacherData } = useTeacherInit();

  const sortedKlasses = useMemo(() => {
    return teacherData?.klasses
      .slice()
      .sort((a, b) => a.klass_name.localeCompare(b.klass_name))
  }, [teacherData]);

  return <Box p={2} display="flex" flexDirection="column" alignItems="center">
    <Box display="flex" alignItems="center">
      <Box textAlign="center" fontFamily="Kodable" style={{ textShadow: '1px 3px rgba(195, 47, 150, 0.5)', color: purp, fontSize: '2rem' }} pb={2} display="flex" alignItems="center" flexDirection="column">
        <img src="/images/valentines/logo-purple.png" style={{ height: '2.5rem', paddingTop: 6 }} />&nbsp;VALENTINE'S DAY
      </Box>
    </Box>

    <Box borderColor={purp} style={{ borderWidth: 4 }} px={8} display="flex" flexDirection="column" alignItems="center" py={4} borderRadius={12} mb={4}>
      <Box textAlign="center" fontFamily="Kodable" style={{ textShadow: '1px 3px rgba(195, 47, 150, 0.5)', color: purp, fontSize: '2rem' }} pb={2}>HOW TO PARTICIPATE</Box>

      <ValentinesDayInstructions />

      <Link style={{ color: purp }} href="http://support.kodable.com/en/articles/5953147-valentine-s-day-2023">View Frequently Asked Questions</Link>
    </Box>

    <Box textAlign="center" fontFamily="Kodable" style={{ textShadow: '1px 3px rgba(195, 47, 150, 0.5)', color: purp, fontSize: '2rem' }} pb={2}>MY CLASSES' VALENTINES</Box>

    <Box maxWidth={1200}>
      <Grid container spacing={4} justifyContent="center">
        {sortedKlasses?.map((klass, index) => {
          return <Grid item key={index}><TeacherValentinesClassListItem klass={klass} /></Grid>
        })}
      </Grid>
    </Box>
  </Box>;
}

const ValentinesDayInstructions: React.FC = () => {
  const connectFamiliesDialogState = useDialogState();

  return <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
    <ConnectWithFamiliesDialog {...connectFamiliesDialogState} event="valentines" small />
    <Box display="flex" alignItems="center" flexDirection="column" justifyContent="space-between" mb={2}>
      <ValentinesDayInstructionBlock
        imgSrc="/images/valentines/teacher-instructions/step1.png"
        title="Send Home Parent Codes"
        subtitle="Invite families via handout or digital link."
      />
      <Button
        variant="contained"
        color="newPink"
        onClick={connectFamiliesDialogState.handleOpen}
      >Invite Families</Button>
    </Box>
    <ValentinesDayInstructionBlock
      imgSrc="/images/valentines/teacher-instructions/step2.png"
      title="Students code a valentine"
      imgPadding={4}
      subtitle="Log into Kodable and the activity will be available between 2/1 &ndash; 2/17."
    />
    <ValentinesDayInstructionBlock
      imgSrc="/images/valentines/teacher-instructions/step3.png"
      title="Families receive valentines"
      subtitle="To open their Valentines, recipients will use the Parent Code."
    />
  </Box>
}

const ValentinesDayInstructionBlock: React.FC<{ title?: string, subtitle?: string, imgSrc?: string, imgPadding?: number }> = ({ title, subtitle, imgSrc, imgPadding }) => {
  return <Box display="flex" flexDirection="column" alignItems="center" width={250} px={1} mb={2}>
    <Box px={imgPadding} height={150} display="flex" alignItems="center" pb={1}>
      <img src={imgSrc} />
    </Box>
    <Typography variant="subtitle1" align="center" style={{ fontWeight: 'bold' }}>{title}</Typography>
    <Typography align="center">{subtitle}</Typography>
  </Box>
}

export const TeacherValentinesClassListItem: React.VFC<{ klass: IKlass }> = ({ klass }) => {
  const { data: valentinesData } = useSWR<{ [key: number]: ValentinesStudentMazeData[] }>(endpoints.valentinesMazesByKlass(klass.id, 'ValentinesDay2023'));
  const { teacherData } = useTeacherInit();
  const valentinesSharingDialogState = useDialogState();

  const numValentines = useMemo(() => {
    if (!valentinesData) {
      return 0;
    }

    return Object.values(valentinesData).reduce((memo, cur) => { return (memo + (cur?.length || 0)) }, 0)
  }, [valentinesData]);

  const students = useMemo(() => {
    return teacherData?.students.filter(({ klass_id }) => klass_id === klass.id) || [];
  }, [teacherData]);

  const linkedStudentsCount = useMemo(() => {
    return students.filter(({ lead }) => lead?.state === ELeadState.linked).length;
  }, [students]);

  const history = useHistory();

  return <Box display="flex" flexDirection="column" alignItems="center" p={2} bgcolor="white" borderRadius={6} width={350} overflow="hidden" height="100%">
    <ValentinesSharingDialog
      {...valentinesSharingDialogState}
      klass={klass}
    />
    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
      <Typography variant="subtitle1">{klass.klass_name}</Typography>
      <Box>
        <FontAwesomeIcon icon={faUser} />&nbsp;{students.length}
      </Box>
    </Box>
    <Box width="100%">
      <Typography>
        {numValentines} {numValentines === 1 ? 'valentine' : 'valentines'} created
      </Typography>
      <Typography>
        {linkedStudentsCount} {linkedStudentsCount === 1 ? 'family' : 'families'} connected
      </Typography>
    </Box>
    <Box display="flex" justifyContent="space-between" width="100%" mt={1}>
      <Button
        variant="outlined"
        size="small"
        color="newPink"
        onClick={valentinesSharingDialogState.handleOpen}
      >Invite Families</Button>
      <Button
        variant="contained"
        size="small"
        color="newPink"
        onClick={() => history.push(routes.valentines.viewClass(klass.id))}
      >View Valentines</Button>
    </Box>
  </Box>
}