import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import { useFormik } from "formik";
import useDialogState from "hooks/useDialogState";
import useTeacherInit from "loaders/useTeacherInit";
import { justFetch } from "mutations/mutate";
import React, { useEffect } from "react";
import { useMemo, useState } from "react";
import { mutate } from "swr";
import { ITeacher } from "types/ITeacher";
import * as Yup from 'yup';

const LicensesTable: React.VFC = () => {
  const sharedClasses = useSharedStyles();

  const { teacherData } = useTeacherInit();
  const assignedLicenses = useMemo(() => {
    return teacherData?.subscription?.licenses
      ?.map(license => ({
        ...license,
        teacher: teacherData.teachers.find(teacher => teacher.id === license.teacher_id)
      }))
      .filter(license => license.teacher);

  }, [teacherData]);

  const assignLicenseDialogState = useDialogState();
  const assignLicenseForm = useFormik({
    initialValues: {
      email: '',
      confirmEmail: ''
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email('Enter a valid email address.')
        .required("Please enter the email address of the teacher to whom you'd like to assign a license."),
      confirmEmail: Yup
        .string()
        .required('Please confirm the email address above.')
        .equals([Yup.ref('email')], "The email you entered doesn't match the one you entered above.")
    }),
    onSubmit: values => {
      setAssignLicenseSubmitError(null)
      return justFetch(endpoints.assignLicense(teacherData!.subscription.purchase_process_id), 'POST', {
        emails: [values.email],
        subscription_id: teacherData!.subscription.id,
        secret_key: teacherData!.subscription.secret_key,
        send_mail: true
      })
        .then(async res => {
          if (!res.ok) {
            const error = await res.json()
            throw new Error(error?.message || 'An unknown error occurred.');
          }

          mutate(endpoints.teacherInit);
          assignLicenseDialogState.onClose();
        })
        .catch((error) => {
          setAssignLicenseSubmitError(error?.message);
        });
    }
  });
  const [assignLicenseSubmitError, setAssignLicenseSubmitError] = useState<string | null>();
  useEffect(() => {
    if (assignLicenseDialogState.open) {
      setAssignLicenseSubmitError(null);
      assignLicenseForm.resetForm();
    }
  }, [assignLicenseDialogState.open])

  const [teacherBeingUnassigned, setTeacherBeingUnassigned] = useState<ITeacher>();
  const [unassignLicenseError, setUnassignLicenseError] = useState(false);

  useEffect(() => {
    if (teacherBeingUnassigned) {
      setUnassignLicenseError(false);
    }
  }, [teacherBeingUnassigned])

  const handleUnassignLicense = () => {
    justFetch(endpoints.unassignLicense(teacherData!.subscription.purchase_process_id), 'PUT', {
      subscription_id: teacherData!.subscription.id,
      teacher_id: teacherBeingUnassigned!.id
    })
      .then(res => {
        if (!res.ok) {
          throw new Error();
        }

        mutate(endpoints.teacherInit);
        setTeacherBeingUnassigned(undefined)
      })
      .catch(() => setUnassignLicenseError(true))
  }

  if (!teacherData) {
    return <Box display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  }

  return <>
    <Dialog {...assignLicenseDialogState} fullWidth>
      <LinearProgress style={{ visibility: assignLicenseForm.isSubmitting ? 'visible' : 'hidden' }}></LinearProgress>
      <form onSubmit={assignLicenseForm.handleSubmit}>
        <DialogTitle>Assign License</DialogTitle>
        <DialogContent className={sharedClasses.vspacing2}>
          <Typography>
            This will add the person below as an admin to your subscription. They will be able to invite other teachers to be part of their school license.
          </Typography>

          <TextField
            id="email"
            name="email"
            label="Email"
            value={assignLicenseForm.values.email}
            onChange={assignLicenseForm.handleChange}
            error={assignLicenseForm.submitCount > 0 && !!assignLicenseForm.errors.email}
            helperText={assignLicenseForm.submitCount > 0 ? assignLicenseForm.errors.email : undefined}
            disabled={assignLicenseForm.isSubmitting}
            onBlur={assignLicenseForm.handleBlur}
          />

          <TextField
            id="confirmEmail"
            name="confirmEmail"
            label="Confirm Email"
            value={assignLicenseForm.values.confirmEmail}
            onChange={assignLicenseForm.handleChange}
            error={assignLicenseForm.submitCount > 0 && !!assignLicenseForm.errors.confirmEmail}
            helperText={assignLicenseForm.submitCount > 0 ? assignLicenseForm.errors.confirmEmail : undefined}
            disabled={assignLicenseForm.isSubmitting}
            onBlur={assignLicenseForm.handleBlur}
          />

          {assignLicenseSubmitError !== null && <Alert severity="error">{assignLicenseSubmitError}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={assignLicenseDialogState.onClose}
            disabled={assignLicenseForm.isSubmitting}
          >Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={assignLicenseForm.isSubmitting}
          >Assign</Button>
        </DialogActions>
      </form>
    </Dialog>
    <Dialog open={!!teacherBeingUnassigned} fullWidth>
      <DialogTitle>Unassign License?</DialogTitle>
      <DialogContent>
        <Typography>
        {teacherBeingUnassigned?.name} ({teacherBeingUnassigned?.username}) and the teachers connected to their account will no longer have access to your subscription.
        </Typography>
        {unassignLicenseError && <Alert severity="error">There was an error submitting this form.</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setTeacherBeingUnassigned(undefined)}
        >
          Cancel
        </Button>
        <Button
          color="red"
          variant="contained"
          onClick={handleUnassignLicense}
        >Unassign</Button>
      </DialogActions>
    </Dialog>
    <Box pb={2} className={sharedClasses.hspacing2}>
      {false && (teacherData?.subscription.licenses.length || 0) - (assignedLicenses?.length || 0) > 0 && <Button
        color="primary"
        variant="contained"
        onClick={assignLicenseDialogState.handleOpen}
        startIcon={<FontAwesomeIcon icon={faPlus} />}
      >Assign License</Button>}
      <Chip
        label={`${assignedLicenses?.length}/${teacherData?.subscription.licenses.length} licenses assigned`}
      />
    </Box>
    {assignedLicenses?.length !== 0 && <TableContainer component={Paper} {...{ variant: 'outlined' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Admin
            </TableCell>
            <TableCell>
              Email
            </TableCell>
            <TableCell>
              School
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assignedLicenses?.map(license => <TableRow key={license.teacher_id}>
            <TableCell>
              {license.teacher!.name}
            </TableCell>
            <TableCell>
              {license.teacher!.username}
            </TableCell>
            <TableCell>
              {license.teacher!.school?.school_name || 'None selected'}
            </TableCell>
            <TableCell style={{ width: 0 }}>
              <Button
                variant="outlined"
                onClick={() => setTeacherBeingUnassigned(license.teacher)}
              >
                Unassign
              </Button>
            </TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>}
    {assignedLicenses?.length === 0 && <Alert severity="info" action={false && <Button size="small" color="inherit" onClick={assignLicenseDialogState.handleOpen}>Assign A License</Button>}>No licenses have been assigned yet.</Alert>}
  </>
}

export default LicensesTable;