import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Paper, Typography } from "@material-ui/core";
import MenuItem from 'components/ui/MenuItem';
import Button from "components/ui/buttons/Button"
import Select from "components/ui/Select";
import useSharedStyles from "components/useSharedStyles";
import useTeacherInit from "loaders/useTeacherInit";
import React, { useEffect, useRef, useState } from "react";
import { useMemo } from "react";
import { useParams } from "react-router";
import { IRouteParams } from "AppRoutes";
import KeyValuePair from "components/data/KeyValuePair";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faPrint, faQrcode } from "@fortawesome/free-solid-svg-icons";
import TextField from "components/ui/TextField";
import { useAlert } from "context/AlertProvider";
import QRCodeDialog from "components/dialogs/QRCodeDialog";
import useDialogState from "hooks/useDialogState";

export interface GettingStartedDialogProps {
  open: boolean;
  onClose?: (...args: any[]) => any
}

const GettingStartedDialog: React.VFC<GettingStartedDialogProps> = ({ open, onClose = () => { } }) => {
  const { teacherData } = useTeacherInit();
  const { classId } = useParams<IRouteParams>();

  const [selectedClassId, setSelectedClassId] = useState<string>(classId || '');
  const selectedClassData = useMemo(() => {
    return teacherData?.klasses.find(({ id }) => id.toString() === selectedClassId);
  }, [teacherData, selectedClassId]);
  const sharedClasses = useSharedStyles();
  const textRef = useRef<HTMLInputElement>(null);
  const alert = useAlert();
  useEffect(() => {
    if (open && classId) {
      setSelectedClassId(classId)
    } else if (open && !classId) {
      setSelectedClassId('');
    }
  }, [open]);
  const [printLoginInstructions, setPrintLoginInstructions] = useState(false);
  const qrCodeDialogState = useDialogState();

  return <Dialog maxWidth="md" fullWidth open={open}>
    <QRCodeDialog {...qrCodeDialogState} classCode={selectedClassData?.klass_code || ''} />
    <DialogTitle>Getting Started</DialogTitle>
    <DialogContent>
      <Box display="flex" flexDirection="column" alignItems="center" className={sharedClasses.vspacing4}>
        {!classId && <Select
          label="Show instructions for class:"
          displayEmpty
          value={selectedClassId}
          onChange={e => setSelectedClassId((e.target.value as any).toString() as string)}
          fullWidth
        >
          <MenuItem disabled value={''}>Select a class</MenuItem>
          {
            teacherData?.klasses.map(klass => <MenuItem
              key={klass.id}
              value={klass.id}
            >
              {klass.klass_name}
            </MenuItem>)
          }
        </Select>}
        {selectedClassId !== '' && <>
          <KeyValuePair
            title={`${selectedClassData?.klass_name} Class Code`}
            value={<Box textAlign="center" component={Paper} bgcolor="background.default" py={2} px={8} {...{ variant: 'outlined' }}>{selectedClassData?.klass_code.toUpperCase()}</Box>}
          />
          <Box display="flex" flexDirection="row" className={sharedClasses.hspacing4}>
            <Button
              variant="contained"
              color="green"
              startIcon={<FontAwesomeIcon icon={faPrint} />}
              onClick={() => setPrintLoginInstructions(true)}
            >Print Student Login Cards</Button>

            <Button
              variant="contained"
              color="blue"
              startIcon={<FontAwesomeIcon icon={faQrcode} />}
              onClick={qrCodeDialogState.handleOpen}
            >Show Class QR Code</Button>
          </Box>

          <Box width="100%"><Divider /></Box>
          <Box width="100%" display="flex" flexDirection="row" alignItems="center" justifyContent="center" className={sharedClasses.hspacing2}>
            <Typography variant="subtitle1">Computer Browser</Typography>
            <Typography>Save this URL to give students a direct link to the game.</Typography>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box width={400} mr={2}>
              <TextField
                fullWidth
                value={`${process.env.REACT_APP_GAME_ENDPOINT}/class/${selectedClassData?.klass_code}`}
                onClick={e => (e.target as any).select()}
                inputProps={{
                  ref: textRef
                }}
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FontAwesomeIcon icon={faCopy} />}
              onClick={() => {
                textRef?.current?.select();
                document.execCommand('copy');
                alert.success('Class Link Copied');
              }}
            >Copy</Button>
          </Box>
          <Box width="100%" display="flex" flexDirection="row" alignItems="center" justifyContent="center" className={sharedClasses.hspacing2}>
            <Typography variant="subtitle1">iPad App</Typography>
            <Typography>Download Kodable from the iOS App Store and use the class code above.</Typography>
          </Box>
        </>}
      </Box>
    </DialogContent>
    <DialogActions>
      <Box>&nbsp;</Box>
      <Button
        variant="outlined"
        onClick={onClose}
      >
        Close
      </Button>
    </DialogActions>
  </Dialog>
};

export default GettingStartedDialog;