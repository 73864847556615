import { Form, FormikProvider, useFormik, useFormikContext } from "formik"
import { GodmodePageWrapper } from "pages/godmode/GodmodePageWrapper"
import { useAlert } from "context/AlertProvider";
import * as Yup from 'yup';
import { Box, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography, makeStyles} from "@material-ui/core";
import Select from "components/ui/Select";
import TextField from "components/ui/TextField";
import Button from "components/ui/buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faChalkboardTeacher, faLock, faPeopleGroup, faSearch, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { justFetch } from "mutations/mutate";
import endpoints from "endpoints";
import React, { useMemo, useState } from "react";
import { Alert } from "@material-ui/lab";
import { useHistory, useRouteMatch} from "react-router-dom";
import { IRouteParams } from "AppRoutes";
import { accountTrackedDate } from "pages/godmode/utils/time";
import useSharedStyles from "components/useSharedStyles";
import classNames from "classnames";

interface IStudentsIndexSearch {
    searchText: string;
    searchType: string
}

interface IStudentFromCodeResponse {
    id: number;
    name: string;
    student_code: string;
    tracked_date: string;
    created_at: string;
    klass: {
        id: number;
        klass_name: string;
        klass_code: string;
    }
    teacher: {
        id: number;
        name: string;
        username: string;
    }
    parent?: {
        id: number;
        name: string;
        username: string;
    }
}

const validationSchema = Yup.object().shape({
    searchText: Yup.string().required('Required field'), 
})

const useStudentsIndexStyles = makeStyles(theme => ({
    studentBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1rem',
        background: 'white',
        border: '1px solid #bebebe',
        borderRadius: '4px',
        marginTop: '1rem',
    },
    titleBox: {
        fontWeight: 'bold',
    },
}));

export const StudentsIndex: React.FC = () => {
    const { path, isExact } = useRouteMatch<IRouteParams>();
    const alert = useAlert()
    const [error, setError] = useState<boolean>(false)
    const [students, setStudents] = useState<null | IStudentFromCodeResponse[]>(null)

    const fetchResults = (values: IStudentsIndexSearch): Promise<Response> => {
        if (values.searchType === 'student_code_search') {
            return justFetch(endpoints.godmode.studentByStudentCode(values.searchText), 'GET');
        } else if (values.searchType === 'student_klass_code_search') {
            return justFetch(endpoints.studentsByClassCode(values.searchText, true, true, true), 'GET');
        } else {
            return Promise.reject(new Error(`Search type ${values.searchType} not supported`));
        }
    };

    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchText: "",
            searchType: "student_code_search"
        },
        validationSchema,
        onSubmit: async (values, actions) => {
            try {
                setError(false)
                setStudents([])
                form.setSubmitting(true)
                const response = await fetchResults(values);
                const parsed = await response.json()
                if (parsed == 'Invalid Class Code')
                {
                    throw new Error(parsed?.message)
                }
                form.setSubmitting(false)
                if (response?.ok && parsed[0]) {
                    setStudents(parsed)
                    return
                }
                throw new Error(parsed?.message)
            } catch (e) {
                form.setSubmitting(false)
                if (values.searchType == 'student_code_search')
                    alert.error('Student Code does not exist!')
                else if (values.searchType == 'student_klass_code_search')
                    alert.error('Klass Code does not exist!')
                else
                    alert.error('Search error: ' + e)

                setError(true)
            }
        }
    })
    
    return (
        <>
        {isExact &&
            <GodmodePageWrapper title="Students" loading={false}>
                <FormikProvider value={form}>
                    <StudentsIndexSearchForm />
                    {error &&
                        <Alert variant="outlined" severity="error">{form.values.searchType == 'student_code_search'? "Student" : "Klass"} Code does not exists!</Alert>
                    }
                    {students && <StudentTable rows={students}/>}
                    { form.isSubmitting && 
                        <Alert severity="warning">Searching...</Alert>
                    }
                </FormikProvider>
            </GodmodePageWrapper>
        }
        </>
    )
}

const headCells: { id: keyof IStudentFromCodeResponse, label: string, icon: IconDefinition | undefined}[] = [
    {
      id: 'name',
      label: 'Student Name',
      icon: undefined
    },
    {
      id: 'student_code',
      label: 'Student Code',
      icon: faLock
    },
    {
      id: 'klass',
      label: 'Class',
      icon: faPeopleGroup
    },
    {
      id: 'teacher',
      label: 'Teacher',
      icon: faChalkboardTeacher
    },
    {
      id: 'parent',
      label: 'Linked Parent',
      icon: faLink
    },
    {
      id: 'tracked_date',
      label: 'Last Active',
      icon: undefined
    }
  ];
  
  const UserTableHeader: React.FC<{ order: 'asc' | 'desc', orderBy: keyof IStudentFromCodeResponse, onRequestSort: (property: keyof IStudentFromCodeResponse) => void }> = ({ order, orderBy, onRequestSort }) => {
    const createSortHandler = (property: keyof IStudentFromCodeResponse) => () => {
      onRequestSort(property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.icon && <FontAwesomeIcon style={{ marginRight: '.5rem' }} icon={headCell.icon} />}{headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
const useTableStyles = makeStyles(() => ({
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#f9f9f9'
      }
    }
  }))

  function descendingComparator(a: IStudentFromCodeResponse, b: IStudentFromCodeResponse, orderBy: keyof IStudentFromCodeResponse) {

    if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
      return (a[orderBy] as string).localeCompare(b[orderBy] as string)
    } else if (orderBy == 'parent' || orderBy == 'teacher')
    {
        var aval =  a[orderBy]?.username || "_";
        var bval =  b[orderBy]?.username || "_";
        if (bval < aval) {
            return -1;
        }
        if (bval > aval) {
            return 1;
        }
    }
    else {
      if (b[orderBy]! < a[orderBy]!) {
        return -1;
      }
      if (b[orderBy]! > a[orderBy]!) {
        return 1;
      }
    }
    return 0;
  }  
  function getComparator(order: 'asc' | 'desc', orderBy: keyof IStudentFromCodeResponse) {
    return order === 'desc'
      ? (a: IStudentFromCodeResponse, b: IStudentFromCodeResponse) => descendingComparator(a, b, orderBy)
      : (a: IStudentFromCodeResponse, b: IStudentFromCodeResponse) => -descendingComparator(a, b, orderBy);
  }
  
  
const StudentTable: React.FC<{ rows: IStudentFromCodeResponse[] }> = ({ rows }) => {
    const [order, setOrder] = useState<'asc' | 'desc'>('desc');
    const [orderBy, setOrderBy] = useState<keyof IStudentFromCodeResponse>('name');
    const tableClasses = useTableStyles();
    const sharedClasses = useSharedStyles();
    const history = useHistory();
  
    const sortedRows = useMemo(() => {
      return rows.slice().sort(getComparator(order, orderBy));
    }, [order, orderBy, rows])
  
    const handleRequestSort = (property: keyof IStudentFromCodeResponse) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    return <Paper variant="outlined">
      <Box>
        <TableContainer>
          <Table size="small">
            <UserTableHeader order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {sortedRows.map((row, i) => {
                return <TableRow
                  hover
                  key={row.id}
                  className={classNames(tableClasses.row, sharedClasses.hoverEnlarge)}
                  onClick={() => {
                    //history.push(routes.godmode.user.account(row.id))
                  }}
                >
                  <TableCell><Typography variant="body2">{row.name}</Typography></TableCell>
                  <TableCell><Typography variant="body2">{row.student_code}</Typography></TableCell>
                  <TableCell><Typography variant="body2">{row.klass?.klass_code}</Typography></TableCell>
                  <TableCell><Typography variant="body2">{row.teacher?.username}</Typography></TableCell>
                  <TableCell><Typography variant="body2">{row.parent?.username}</Typography></TableCell>
                  <TableCell><Typography variant="body2">{accountTrackedDate(row.tracked_date || row.created_at)}</Typography></TableCell>                  
                </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  }

const StudentsIndexSearchForm: React.FC = () => {
    const { values, touched, errors, isSubmitting, setSubmitting, handleChange, setFieldValue } = useFormikContext<IStudentsIndexSearch>();
    const [searchType, setSearchType] = useState("student_code_search");
    const handleSearchTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSearchType(event.target.value as string);
        setFieldValue("searchType", event.target.value as string);
    };
    
    return (
        <Form>
            <Box display="flex" alignItems="center" mb={1}>
                <Box width={175} mr={1}>
                    <Select
                        value={values.searchType}
                        fullWidth
                        disabled={isSubmitting}
                        onChange={handleSearchTypeChange}
                    >
                        <MenuItem value="student_code_search" selected>Student Code</MenuItem>
                        <MenuItem value="student_klass_code_search" selected>Klass Code</MenuItem>
                    </Select>
                </Box>
                <Box flexGrow={1}>
                    <TextField
                        value={values.searchText}
                        onChange={handleChange}
                        error={touched.searchText && !!errors.searchText}
                        helperText={touched.searchText && errors.searchText}
                        name="searchText"
                        id="searchText"
                        placeholder={searchType == 'student_code_search'? "Search by Student Code" : "Search by Klass Code"}
                        fullWidth
                        disabled={isSubmitting}
                    />
                </Box>
                <Box ml={1}>
                    <Button
                        color="green"
                        variant="contained"
                        startIcon={<FontAwesomeIcon icon={faSearch} />}
                        size="large"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        Search
                    </Button>
                </Box>
              </Box>
        </Form>
    )
}