import { Box } from "@material-ui/core"
import { IRouteParams } from "AppRoutes"
import useTeacherInit from "loaders/useTeacherInit";
import { useParams } from "react-router-dom"
import QRCode from "react-qr-code";
import React from "react";

export const ParentLettersPrintout: React.VFC = () => {
  const { classId } = useParams<IRouteParams>();
  const { teacherData } = useTeacherInit();
  const students = teacherData?.students.filter(({ klass_id }) => klass_id?.toString() === classId);

  return <>
    {students?.map(student => {
      return (
      <Box style={{ pageBreakAfter: 'always' }} width="90%" marginX="auto" position="relative">
        <img src="/images/printouts/parent_letter.jpg" />
        <QRCode
          value={`https://kodable.com/parent-code?redirect=true&student=${student?.student_code}`}
          style={{
            width: '150px',
            position: 'absolute',
            top: '30%',
            left: '-14px',
            right: '0',
            margin: 'auto'
          }}
        />
        <Box
          fontSize={36}
          position="absolute"
          top="5%"
          lineHeight={1}
          left="0%"
          width="100%"
          textAlign="center"
          fontWeight="bold"
          fontFamily="Kodable"
        >
          {student.name}
        </Box>
        <Box
          fontSize={36}
          position="absolute"
          top="40%"
          left="65%"
          width="27%"
          textAlign="center"
          style={{ textTransform: 'uppercase', fontFamily: 'monospace' }}
        >
          {student.student_code}
        </Box>
      </Box>
    )})}
  </>
}