import { faEnvelope, faBriefcase, faSchool } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography, CircularProgress, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { IRouteParams } from "AppRoutes";
import useSharedStyles from "components/useSharedStyles";
import { useOrganizationProfile } from "context/OrganizationProfileProvider";
import React, { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { ILicense } from "types/ILicense";
import { ISubscription } from "types/ISubscription";
import { ITeacher } from "types/ITeacher";
import { accountTrackedDate } from "pages/godmode/utils/time";
import Button from "components/ui/buttons/Button";
import { SubscriptionAssignLicenseButton } from "pages/godmode/Subscriptions/assign-license/SubscriptionAssignLicenseButton";

export const OrganizationProfileDetailLicensesTab: React.FC = () => {
    const sharedClasses = useSharedStyles()
    const { params } = useRouteMatch<IRouteParams>();
    const { organization_profile } = useOrganizationProfile();
    const [subscription, setSubscription] = useState<ISubscription>()
    const [licenses, setLicenses] = useState<ILicense[]>()
    const [teachers, setTeachers] = useState<ITeacher[]>()

    useEffect(() => {
        const sub = organization_profile?.subscriptions?.find((sub) => sub.id === Number(organization_profile?.current_subscription?.id))
        if (!sub) {
            return
        }
        console.log(sub)
        setSubscription(sub)

        return () => {
            setSubscription(undefined)
        }
    }, [organization_profile, params])

    useEffect(() => {
        if (!subscription || !subscription?.licenses) {
            return
        }
        const licenses = subscription?.licenses?.filter?.((license) => license?.activated)
        setLicenses(licenses)
    }, [subscription])

    useEffect(() => {
        if (!organization_profile?.teachers) {
            return
        }
        setTeachers(organization_profile?.teachers)
    }, [organization_profile])

    return (
        <Box className={sharedClasses?.vspacing2}>
            <Typography variant="h2">All - {subscription?.license_count} site{subscription?.license_count !== 1 ? 's': ''}</Typography>
            <Box display="flex" p={2} style={{border: '1px solid #eee', background: "white"}} width="65%">
                <Box className={sharedClasses?.vspacing2} display="flex" flexDirection="column" width="50%">
                    <Typography variant="h1">Account Super Admin</Typography>
                    <Typography>{organization_profile?.administrator?.name} | Admin Code: <strong>{organization_profile?.administrator?.administrator_code}</strong></Typography>
                    <Typography><FontAwesomeIcon icon={faEnvelope} /> {organization_profile?.administrator?.username}</Typography>
                    <Typography><FontAwesomeIcon icon={faBriefcase} /> {organization_profile?.administrator?.occupation}</Typography>
                    <Typography><FontAwesomeIcon icon={faSchool} /> {organization_profile?.display_name}</Typography>
                </Box>
                <Box className={sharedClasses?.vspacing2} display="flex" flexDirection="column" width="50%" alignItems="flex-end">
                    <Box textAlign="right">
                        <Typography><strong>Signed Up</strong></Typography>
                        <Typography>{organization_profile?.administrator?.created_at ? accountTrackedDate(organization_profile?.administrator?.created_at) : ''}</Typography>
                    </Box>
                    <Box textAlign="right">
                        <Typography><strong>Last Active</strong></Typography>
                        <Typography>{organization_profile?.administrator?.updated_at ? accountTrackedDate(organization_profile?.administrator?.updated_at) : ''}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Box display="flex" alignItems="center" justifyContent="center" p={2} style={{background: '#5e2fac', color: 'white'}} mb={2}>
                    <Typography variant="h2">Assigned Licenses {`(${licenses?.length} / ${subscription?.license_count})`}</Typography>
                </Box>
                {(subscription?.license_count && subscription?.license_count > 0) &&
                    <SubscriptionAssignLicenseButton subscriptionId={subscription?.id} />
                }
                {!licenses &&
                    <Box display="flex" alignItems="center" justifyContent="center" width="100%" p={4}>
                        <CircularProgress />
                    </Box>
                }
                {licenses && licenses?.length === 0 &&
                    <Box display="flex" alignItems="center" justifyContent="center" width="100%" p={4}> 
                        <Typography variant="body1" align="center">No licenses assigned</Typography>
                    </Box>
                }
                {licenses && licenses?.length > 0 &&
                <Box className={sharedClasses?.vspacing2} mt={2} mb={2}>
                    <TextField
                        label="Search"
                        variant="outlined"
                        size="small"
                        placeholder="Search by email or name"
                    />
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>School Name</TableCell>
                                    <TableCell>Remove</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {licenses?.map((license) => {
                                    const teacher = teachers?.find((teacher) => teacher.id === license?.teacher_id)
                                    return (
                                        <TableRow>
                                            <TableCell>{teacher?.name}</TableCell>
                                            <TableCell>{teacher?.username}</TableCell>
                                            <TableCell>{organization_profile?.display_name}</TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="outlined"
                                                    color="red"
                                                    size="small"
                                                >
                                                    Remove
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                }
            </Box>
        </Box>
    )
}