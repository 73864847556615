import {DialogStateProps} from "../../../hooks/useDialogState";
import {IFuzzData} from "../../../types/IFuzzData";
import {IStudentSchoolProfile} from "../../../types/IStudentSchoolProfile";
import {IMazeData} from "../../../types/IMazeData";
import useSharedStyles from "../../useSharedStyles";
import React, {useEffect, useMemo, useState} from "react";
import {useAlert} from "../../../context/AlertProvider";
import {justFetch} from "../../../mutations/mutate";
import endpoints from "../../../endpoints";
import {mutate} from "swr";
import {Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import Button from "../../ui/buttons/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

const DeleteStudentWorkDialog: React.VFC<DialogStateProps & {
  fuzz?: IFuzzData,
  maze?: IMazeData,
  student: IStudentSchoolProfile,
}> = ({open, onClose, fuzz, maze, student}) => {
  const sharedClasses = useSharedStyles();
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState(false);

  const creationType = useMemo(() => {
    if (!!fuzz) {
      return 'fuzz';
    }

    return 'maze';
  }, [fuzz, maze]);

  const creationName = useMemo(() => {
    if (fuzz) {
      return fuzz.config.clazzName;
    }

    if (maze) {
      return maze.title;
    }
  }, [maze, fuzz]);

  const alert = useAlert();

  const doDelete = () => {
    setIsDeleting(true);
    justFetch(!!fuzz ? endpoints.deleteFuzz(fuzz.client_id) : endpoints.deleteMaze(student?.id, maze!.id), 'DELETE', fuzz || {
      student_code: student.student_code,
      deleted: true
    })
      .then(res => {
        setIsDeleting(false);
        if (res.ok) {
          alert.success(`${creationType[0].toLocaleUpperCase() + creationType.slice(1)} deleted`);

          if (!!fuzz) {
            mutate(endpoints.fuzzesByClass(student.klass_id!));
          }

          if (!!maze) {
            mutate(endpoints.mazesByClass(student.klass_id!));
          }

          onClose();
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        setDeleteError(true);
        setIsDeleting(false);
      })
  }

  useEffect(() => {
    setIsDeleting(false);
    setDeleteError(false);
  }, [open]);


  return <Dialog open={open} fullWidth>
    <LinearProgress style={{visibility: isDeleting ? 'visible' : 'hidden'}}/>
    <DialogTitle>Delete this {creationType}?</DialogTitle>
    <DialogContent className={sharedClasses.vspacing2}>
      <Typography>
        This will delete the {creationType} <strong>{creationName}</strong> by <strong>{student.name}</strong>.
      </Typography>

      {deleteError &&
        <Alert severity="error">There was an error deleting this {creationType}. Please try again.</Alert>}

    </DialogContent>
    <DialogActions>
      <Button
        variant="outlined"
        onClick={onClose}
        disabled={isDeleting}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        startIcon={<FontAwesomeIcon icon={faTrash}/>}
        color="red"
        onClick={doDelete}
        disabled={isDeleting}
      >
        Delete
      </Button>
    </DialogActions>
  </Dialog>
}

export default DeleteStudentWorkDialog