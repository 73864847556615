import {
  Box,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  Paper,
  Typography
} from "@material-ui/core";
import {Close, ExpandMore} from "@material-ui/icons";
import {IRouteParams} from "AppRoutes";
import useSharedStyles from "components/useSharedStyles";
import {useTracking} from "context/TrackingProvider";
import {motion} from "framer-motion";
import useDialogState from "hooks/useDialogState";
import useTeacherInit from "loaders/useTeacherInit";
import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from "react";
import {useRouteMatch} from "react-router-dom";
import YouTube from "react-youtube";
import {MultiClassPrintoutDialog} from "pages/printouts/MultiClassPrintoutDialog";
import GameSelection from "./GameSelection";
import {GameTitle} from "../../../enums/GameTitle";
import {NoStudentsWidget} from "./widgets/NoStudentsWidget";
import {StudentInstructionsDialog} from "../../../components/dialogs/StudentInstructionsDialog";
import {NonCleverInstructions} from "./NonCleverInstructions";
import {SidebarContent} from "./SidebarContent";
import {useRecentActivity} from "../../../hooks/useRecentActivity";
import {routes} from "../../../routes";

const ViewClassDashboard = () => {
  return <Box display="flex" flexDirection="row" flexGrow={1}>
    <Box width="65%" display="flex" flexDirection="column" flexGrow={1} maxHeight="calc(100vh - 156px)">
      <MainContent />
    </Box>
    <SidebarContent />
  </Box>;
}

const MainContent: React.VFC = () => {
  const { params } = useRouteMatch<IRouteParams>();
  const { teacherData } = useTeacherInit();
  const currentClass = useMemo(() => {
    return teacherData?.klasses.find(({ id }) => id.toString() === params.classId)!;
  }, [teacherData, params.classId]);
  const students = useMemo(() => {
    return teacherData!.students.filter(({ klass_id }) => klass_id === currentClass?.id);
  }, [teacherData, currentClass]);
  const { loading: recentActivityLoading } = useRecentActivity(currentClass.id);

  const numStudents = students.length;

  if (recentActivityLoading) {
    return <Box display="flex" justifyContent="center" p={4}>
      <CircularProgress />
    </Box>
  }

  if (numStudents === 0) {
    return <MainContentNoStudents />
  }

  return (
    <Box overflow="scroll">
      <GameSelection klass={currentClass} />
      <MainContentStudentInstructions />
    </Box>
  )
}

const MainContentNoStudents: React.VFC = () => {
  const { params } = useRouteMatch<IRouteParams>();
  const { track } = useTracking();

  useEffect(() => {
    track('Viewed Class Home Tab', {
      "Page": "No Students",
      'Class ID': params.classId
    });

  }, []);

  return <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
    <NoStudentsWidget klass_id={params.classId} />
  </Box>;
}

export const MainContentStudentInstructions: React.VFC = () => {
  const { params } = useRouteMatch<IRouteParams>();
  const { teacherData } = useTeacherInit();
  const currentClass = useMemo(() => {
    return teacherData?.klasses.find(({ id }) => id.toString() === params.classId)!;
  }, [teacherData, params.classId]);
  const studentInstructionsDialogState = useDialogState();
  const sharedClasses = useSharedStyles();
  const { track } = useTracking();

  useEffect(() => {
    track('Viewed Class Home Tab', {
      "Page": "Student Instructions",
      'Class ID': currentClass.id
    })
  }, []);

  return <>
    <StudentInstructionsDialog {...studentInstructionsDialogState} klass={currentClass} />
    <Box flexGrow={1} p={4} height="100%">
      <Box maxWidth={800} marginBottom={'3rem'}>
        <Typography paragraph variant="h1">Get Students Logged In</Typography>
        <Box className={sharedClasses?.vspacing4}>
          {currentClass.klass_settings_data.AssignedGames.includes(GameTitle.BASICS) && <NonCleverInstructions game={GameTitle.BASICS} klass={currentClass} />}
          {currentClass.klass_settings_data.AssignedGames.includes(GameTitle.CREATOR) && <NonCleverInstructions game={GameTitle.CREATOR} klass={currentClass} />}
        </Box>
      </Box>
    </Box>
  </>;
}

export const InfoCard: React.VFC<{ title?: React.ReactNode, icon?: string, teaser?: React.ReactNode, content?: React.ReactNode }> = ({ title, icon, teaser, content }) => {
  const [open, setOpen] = useState(false);
  const sharedClasses = useSharedStyles();

  return <>
      <Typography variant="subtitle1" style={{ fontSize: 20, display: 'flex', alignItems: 'center' }}>
        {!!icon &&
          <img style={{marginRight: '1rem', width: '52px'}} width="52" height="52" src={icon} alt="" />
        }
        {title}
      </Typography>
      <Box pt={2}>{teaser}</Box>
      <Collapse in={open}><Box pt={2}>{content}</Box></Collapse>
      <Box pt={2} onClick={() => setOpen(o => !o)} className={sharedClasses.hoverCursorPointer}>
        <Link><MotionExpandMore initial={{ rotate: 0 }} animate={{ rotate: open ? 180 : 0 }} transition={{ duration: 0.5 }} />read {open ? 'less' : 'more'}</Link>
      </Box>
    </>;
}

const MotionExpandMore = motion.custom(ExpandMore);

export type ResourceCardProps = { thumbnail: string, href?: string, ytVideoId?: string, type: string, title: string, dialogState?: {open: boolean, setOpen: Dispatch<SetStateAction<boolean>>} };
export const ResourceCard: React.VFC<ResourceCardProps> = ({ thumbnail, href: url, type, title, ytVideoId, dialogState }) => {
  const videoDialogState = useDialogState();
  const sharedClasses = useSharedStyles();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!!dialogState) {
      e.preventDefault()
      dialogState.setOpen(true);
    }
  }

  return (
  <>
    {dialogState &&
      <MultiClassPrintoutDialog
      title="Get logged in to Kodable"
      helperText="Print or digitally send code cards to get students logged in at school and at home"
      open={dialogState.open}
      onClose={() => {
          dialogState.setOpen(false);
      }}
      handoutUrl={(klassId) => routes.handouts.studentHandouts(klassId).slice(1)}
      filename={(klass) => `Kodable Student Handouts - ${klass.klass_name || "New Class"}`}
      v2
      />
    }
    <a href={url ? url : ''} onClick={handleClick} target={url ? "_blank" : ''} rel="noreferrer">
      <Dialog open={videoDialogState.open} fullWidth>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Close style={{ visibility: 'hidden' }} />
            <Typography variant="h1" align="center">{title}</Typography>
            <Close className={sharedClasses.hoverCursorPointer} onClick={videoDialogState.onClose} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" mb={2}>
            <YouTube videoId={ytVideoId || ''} opts={{ width: '550', height: '300' }} />
          </Box>
        </DialogContent>
      </Dialog>
      <Box component={Paper} {...{ variant: 'outlined' }} display="flex" flexDirection="row" alignItems="center" maxHeight={96} minWidth={320} width={340} onClick={() => ytVideoId ? videoDialogState.handleOpen() : 1} className={sharedClasses.hoverCursorPointer}>
        <img src={thumbnail} style={{ width: 125, borderTopLeftRadius: 3, borderBottomLeftRadius: 3 }} />
        <Box display="flex" flexDirection="column" mx={2}>
          <Typography variant="body2">{type}</Typography>
          <Typography variant="subtitle1">{title}</Typography>
        </Box>
      </Box>
    </a>
  </>
  )
}

export default ViewClassDashboard;