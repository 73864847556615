import { Box, Typography } from "@material-ui/core";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import useCurrentUser from "loaders/useCurrentUser";
import React, { Dispatch, SetStateAction } from "react";
import { FormikProps, useFormik } from "formik";
import IAddNewClassRequest from "types/IAddNewClassRequest";
import { justFetch } from "mutations/mutate";
import { IKlass } from "types/IKlass";
import * as Yup from 'yup';
import { AddClassFormProvider } from "context/AddClassFormProvider";
import { AddClassManualFormNameGrade } from "./AddClassManualFormNameGrade";
import { AddClassManualFormStudents } from "./AddClassManualFormStudents";
import { AddClassManualFormAssignGames } from "./AddClassManualFormAssignGames";
import {GameTitle} from "../../../enums/GameTitle";

export type AddNewClassManualStep = 'name-grade' | 'add-students' | 'assign-games';

export interface IAddClassManualFormProps {
    stepper: {
        step: AddNewClassManualStep;
        setStep: Dispatch<SetStateAction<AddNewClassManualStep>>;
    }
    handleSuccess: (createdClass: IKlass) => void;
    handleError: (message: string) => void;
}

export const AddClassManualForm: React.VFC<IAddClassManualFormProps> = ({stepper, handleSuccess, handleError}) => {
    const { currentUser } = useCurrentUser();
    const sharedClasses = useSharedStyles();
    const { step, setStep } = stepper

    const addNewClassForm: FormikProps<IAddNewClassRequest> = useFormik<IAddNewClassRequest>({
        validateOnChange: true,
        isInitialValid: false,
        initialValues: {
          grade: 'all',
          klass_code: undefined,
          klass_name: '',
          password_enabled: false,
          students: [],
          teacher_id: currentUser.id,
          assigned_games: [GameTitle.BASICS, GameTitle.CREATOR]
        },
        onSubmit: async values => {
            const res = await justFetch(endpoints.classes, 'POST', values);

            if (res.ok) {
                const createdClass = await res.json() as IKlass
                handleSuccess(createdClass)
                return
            }

            res.json().then(body => handleError(body.message || body.error || 'An unknown error occurred.'))
        },
        validationSchema: Yup.object({
            klass_name: Yup.string().required('Enter a name for your class.')
        })
    })

    return (
        <Box className={sharedClasses.vspacing2}>
            <Typography
                variant="h2"
                align="center"
                style={{
                    fontFamily: 'Bjola',
                    fontSize: '2.5rem',
                }}
            >
                {step === 'name-grade' &&
                    <>Name Your Class</>
                }
                {step === 'add-students' &&
                    <>Add Students</>
                }
                {step === 'assign-games' &&
                    <>Suggested Courses</>
                }
            </Typography>
            <Box className={sharedClasses.vspacing2} display="flex" flexDirection="column" flexGrow={1}>
                <AddClassFormProvider addNewClassForm={addNewClassForm}>
                    {step === 'name-grade' &&
                        <AddClassManualFormNameGrade setStep={setStep} />
                    }
                    {step === 'add-students' &&
                        <AddClassManualFormStudents setStep={setStep} />
                    }
                    {step === 'assign-games' &&
                        <AddClassManualFormAssignGames />
                    }
                </AddClassFormProvider>
            </Box>
        </Box>
    )
}