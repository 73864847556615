import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, Link, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import { useAlert } from "context/AlertProvider";
import endpoints from "endpoints";
import { DialogStateProps } from "hooks/useDialogState";
import useTeacherInit from "loaders/useTeacherInit";
import { justFetch } from "mutations/mutate";
import React, {useEffect, useMemo, useRef, useState} from "react";

export interface DialogParentConnectLinkProps extends DialogStateProps {
  selectedKlassId?: number,
  event?: 'valentines'
}

const ParentConnectLinkDialog: React.VFC<DialogParentConnectLinkProps> = ({ open, onClose, selectedKlassId: _selectedKlassId, event }) => {
  const { teacherData } = useTeacherInit();
  const sharedClasses = useSharedStyles();
  const [selectedKlassId, setSelectedKlassId] = useState(_selectedKlassId);
  const [secretCode, setSecretCode] = useState<number>();
  const [error, setError] = useState(false);
  const linkTextRef = useRef<HTMLInputElement>(null);
  const messageTextRef = useRef<HTMLTextAreaElement>(null);
  const alert = useAlert();
  const klass = useMemo(() => {
    return teacherData?.klasses.find(({ id }) => id === _selectedKlassId);
  }, [_selectedKlassId])

  useEffect(() => {
    if (selectedKlassId) {
      justFetch(endpoints.klass_secret_code(selectedKlassId), 'GET')
        .then(res => {
          if (!res.ok) {
            throw new Error();
          }

          return res.json();
        })
        .then(res => setSecretCode(res))
        .catch(() => setError(true))
    }
  }, [selectedKlassId])

  useEffect(() => {
    setSelectedKlassId(_selectedKlassId);
  }, [_selectedKlassId]);

  return <Dialog open={open} scroll="paper">
    <DialogTitle>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Close style={{ visibility: 'hidden' }} />
        <Box>
          {klass?.klass_name} invite link
        </Box>
        <Close className={sharedClasses.hoverCursorPointer} onClick={onClose} />
      </Box>
    </DialogTitle>
    <DialogContent>
      {selectedKlassId && !secretCode && !error && <Box display="flex" justifyContent="center" pt={2}>
        <CircularProgress />
      </Box>}
      {secretCode && <Box display="flex" flexDirection="column" alignItems="center">
        {teacherData?.students.filter(({ klass_id }) => klass_id === selectedKlassId).length === 0 && <Alert severity="warning" style={{ marginBottom: 16 }}>This class has no students in it.</Alert>}
        <Typography variant="subtitle1">Share this link with parents:</Typography>
        <Box display="flex" flexDirection="row" mb={4}>
          <Box width={400} mr={2}>
            <TextField
              fullWidth
              value={`${process.env.REACT_APP_KODABLE_WEBSITE}/${event === 'valentines' ? 'valentines-' : ''}connect?code=${secretCode}`}
              onClick={e => (e.target as any).select()}
              inputProps={{
                ref: linkTextRef
              }}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            startIcon={<FontAwesomeIcon icon={faCopy} />}
            onClick={() => {
              linkTextRef?.current?.select();
              document.execCommand('copy');
              alert.success('Class Link Copied');
            }}
          >Copy</Button>
        </Box>
        <Typography variant="subtitle1">Example email:</Typography>
        <Box width="100%" mb={2}>
          <TextField rows={12} multiline fullWidth inputRef={messageTextRef}
            value={`We're learning to code! Connect with your student at home to track progress, unlock exclusive gameplay and view their amazing projects and activities.

To connect:

1. Go to ${process.env.REACT_APP_KODABLE_WEBSITE}/connect?code=${secretCode}
2. If you're new to Kodable, click sign up, otherwise log in. 
3. Connect to class`}
          />
        </Box>
        <Box mb={2} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            startIcon={<FontAwesomeIcon icon={faCopy} />}
            onClick={() => {
              messageTextRef?.current?.select();
              document.execCommand('copy');
              alert.success('Message Copied');
            }}
          >Copy</Button>
        </Box>
        <Box>
          <Typography variant="h2" align="center">
            We care about student safety!
          </Typography>
          <Typography variant="body2" align="center">
            Per our <Link href="https://www.kodable.com/privacy" target="_blank">Terms of Service</Link>, Personally Identifiable Information should never be used for student profiles. If you are not using PII for student profiles, you can safely use this feature. However, it is always safe to confirm that you are authorized to share anonymized profile information with parents before using this feature. If you feel that you are not authorized to share this information, use the Parent Code option instead.
          </Typography>
        </Box>
      </Box>}
    </DialogContent>
  </Dialog>
}

export default ParentConnectLinkDialog