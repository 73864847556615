import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle, faPaperPlane, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, DialogTitle, Box, DialogContent, Typography, TextField, InputAdornment, Paper, ListItem, ListItemText, DialogActions, CircularProgress, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import ParentInviteEmailForm from "components/form/ParentEmailInviteForm";
import Button from "components/ui/buttons/Button";
import useSharedStyles from "components/useSharedStyles";
import { useAlert } from "context/AlertProvider";
import endpoints from "endpoints";
import { AnimatePresence, motion } from "framer-motion";
import { DialogStateProps } from "hooks/useDialogState";
import useTeacherInit from "loaders/useTeacherInit";
import { justFetch } from "mutations/mutate";
import React, { useState, useMemo, useEffect, useRef, Dispatch, SetStateAction } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "routes";
import grades, { gradesArray } from "types/Grades";
import { IKlass } from "types/IKlass";
import ParentInvites, { TrackParentInviteMethod, TrackParentInviteMethodLocation } from "utils/ParentInvites";

export const GenerateMultipleClassLinksDialog: React.VFC<DialogStateProps & { event?: 'valentines', location?: TrackParentInviteMethodLocation }> = ({ open, onClose, event, location}) => {
  const [classNameFilter, setClassNameFilter] = useState('');
  const { teacherData } = useTeacherInit();
  const [selectedKlass, setSelectedKlass] = useState<number>(0)

  const sharedClasses = useSharedStyles();

  const classesByGrade = useMemo(() => {
    const classes = teacherData?.klasses || [];

    return gradesArray.reduce((memo, cur) => {
      return {
        ...memo,
        [cur.key]: classes.filter(klass => klass.grade === cur.key && klass.klass_name.toLowerCase().includes(classNameFilter.toLowerCase())).sort((klassA, klassB) => klassA.klass_name.localeCompare(klassB.klass_name))
      }
    }, {} as { [key in keyof typeof grades]: IKlass[] });
  }, [teacherData, classNameFilter]);

  const totalResults = useMemo(() => {
    return Object.values(classesByGrade).reduce((memo, arr) => memo + arr.length, 0);
  }, [classesByGrade]);

  const history = useHistory();

  return <Dialog maxWidth={selectedKlass > 0 ? "sm" : "md"} open={open} scroll="paper">
    <DialogTitle>Send Invites to Families</DialogTitle>
    {((teacherData?.klasses?.length || 0) > 0 && selectedKlass === 0) && <Box component={DialogContent} className={sharedClasses.vspacing2} display="flex" flexDirection="column">
      <Typography align="center">Email the class link to families and they'll receive joining info, or copy to share on another platform</Typography>
      <TextField
        placeholder="Search Classes"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon={faSearch} />
            </InputAdornment>
          ),
        }}
        value={classNameFilter}
        onChange={e => setClassNameFilter(e.target.value)}
      />
      {totalResults === 0 && <Alert severity="info">Your search returned no results.</Alert>}
      {totalResults > 0 && <Box component={Paper} {...{ variant: 'outlined' }} px={2} pb={2} overflow="scroll">
        {gradesArray.map(grade => {
          const klasses = classesByGrade[grade.key];

          if (klasses.length === 0) {
            return null;
          }

          return <React.Fragment key={grade.key}>
            <Box color={grade.color} borderBottom={`2px solid ${grade.color}`} mt={2} mb={1}>
              <Typography variant="subtitle1">{grade.name}</Typography>
            </Box>

            <AnimatePresence>
              {klasses.map((klass, idx) => {
                const klassStudents = teacherData?.students?.filter((student) => {
                  return student?.klass_id === klass?.id
                })


                return <motion.div
                  key={klass.id}
                  initial={{ opacity: 0, maxHeight: 0, overflow: 'hidden' }}
                  animate={{ opacity: 1, maxHeight: 125, overflow: 'visible' }}
                  exit={{ opacity: 0, maxHeight: 0, overflow: 'hidden' }}
                  transition={{ duration: 0.25 }}
                >
                  <ListItem
                    style={{ backgroundColor: idx % 2 === 0 ? 'rgba(0,0,0,0.03)' : '', borderRadius: 6 }}
                  >
                    <ListItemText
                      primary={<ParentConnectLinkRow disabled={klassStudents?.length === 0} setSelectedKlass={setSelectedKlass} klass={klass} event={event} location={location} />}
                    />
                  </ListItem>
                </motion.div>
              })}
            </AnimatePresence>
          </React.Fragment>
        })}
      </Box>}
    </Box>}
    {(teacherData && ((teacherData?.klasses?.length || 0) > 0) && selectedKlass > 0) && 
      <DialogContent>
        <ParentInviteEmailForm
          teacherData={teacherData}
          klassID={selectedKlass}
          location={location}
        />
      </DialogContent>
    }
    {teacherData?.klasses?.length === 0 && <Alert severity="info" action={<Button color="inherit" size="small" onClick={() => history.push(routes.classes.addNew.index)}>Add a class</Button>}>You don't have any classes to generate reports.</Alert>}
    <DialogActions>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Button
          variant="outlined"
          onClick={onClose}
        >Close</Button>
      </Box>
    </DialogActions>
  </Dialog>
}

const ParentConnectLinkRow: React.VFC<{ klass: IKlass, event?: 'valentines', setSelectedKlass: Dispatch<SetStateAction<number>>, location?: TrackParentInviteMethodLocation, disabled?: boolean }> = ({ klass, event, setSelectedKlass, location, disabled = false }) => {
  const [secretCode, setSecretCode] = useState<number>()
  const [error, setError] = useState(false)
  const linkTextRef = useRef<HTMLInputElement>(null)
  const [copied, setCopied] = useState<boolean>(false)
  const [failures, setFailures] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const theme = useTheme();

  useEffect(() => {
    setLoading(true)
    justFetch(endpoints.klass_secret_code(klass.id), 'GET')
      .then(res => {
        if (!res.ok) {
          throw new Error();
        }
        return res.json();
      })
      .then(res => {
        setSecretCode(res)
        setLoading(false)
      })
      .catch(() => {
        setError(true)
        setLoading(false)
      })
      
  }, [klass, failures]);

  const alert = useAlert();

  const handleTracking = () => {
    // Track in database
    ParentInvites.track(klass, TrackParentInviteMethod.link, location)
  }

  const handleCopy = () => {
    if (loading) {
      alert.error('Class Link is still loading', 'Please try again in a moment')
      return
    }
    if (!secretCode) {
      // increments failures to cause automatic retry of useEffect that fetches secret klass code
      // up to 5 times
      alert.error(
        'Could not copy Class Link',
        failures < 5 ? 'Please try again in a moment' : 'Please refresh the page and try again'
      )
      if (failures < 5) {
        setFailures((current) => current + 1)
      }
      return
    }
    
    // Select text in field, copy, alert, and deselect (blur) text in field
    linkTextRef?.current?.select();
    document.execCommand('copy');
    alert.success('Class Link Copied');
    linkTextRef?.current?.blur();

    // Temporarily changes button text/icon to further indicate that the link was copied successfully
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 3000)

    // Track that user has copied the link
    handleTracking()
  }

  return <>
    <Box
    display="flex"
    alignItems="center"
    justifyContent={disabled ? 'space-between' : ''}
    >
      <Box maxWidth={300} minWidth={100} flexGrow={1} overflow="hidden" mr={2}>
        <Typography style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {klass.klass_name}
        </Typography>
      </Box>
      {error && <Typography color="error">Error generating code</Typography>}
      {!secretCode && !error && <CircularProgress size="1rem" />}
      {(secretCode && !error) && <>
        {!disabled &&
        <>
          <Box mr={2} width={400}>
            <TextField
              style={{cursor: loading ? 'not-allowed' : 'pointer'}}
              disabled={loading}
              onClick={handleCopy}
              fullWidth
              value={`${process.env.REACT_APP_KODABLE_WEBSITE}/${event === 'valentines' ? 'valentines-' : ''}connect?code=${secretCode}`}
              inputProps={{
                ref: linkTextRef,
                style: { color: theme.palette.blue.main, cursor: 'pointer' }
              }}
              InputProps={{
                disableUnderline: true
              }}
            />
          </Box>
          <Button
            style={{cursor: loading ? 'not-allowed' : 'pointer', marginRight: '.5rem'}}
            disabled={loading}
            onClick={handleCopy}
            variant="outlined"
            color="primary"
            startIcon={<FontAwesomeIcon icon={copied ? faCheckCircle : faCopy} />}
          >{copied ? 'Copied!' : 'Copy'}</Button>
          <Button
            style={{cursor: loading ? 'not-allowed' : 'pointer'}}
            disabled={loading}
            onClick={() => {
              setSelectedKlass(klass?.id)
            }}
            variant="contained"
            color="primary"
            startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
          >Invite</Button>
        </>
        }
        {disabled &&
        <Box>
          <Typography variant="body1">No students in this class</Typography>
        </Box>
        }
      </>}
    </Box>
  </>
}