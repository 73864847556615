import {IFuzzData} from "./IFuzzData";

export enum ELeadState {
  invited = "invited",
  linked = "linked",
  not_invited = "not_invited"
}

export interface IStudentSchoolProfile {
  id: number;
  name: string;
  klass_id?: number;
  teacher_id: number;
  student_code: string;
  updated_at: string;
  created_at: string;
  lead?: {
    state?: ELeadState;
    email?: string;
  }
  is_teacher: boolean;
  tracked_date?: string;
  fuzzes?: IFuzzData[];
}